import { Table, Text, Box, Tooltip, Button } from '@mantine/core'
import { nanoid } from 'nanoid'

import { Link } from 'react-router-dom'
import { formatUTCDate } from 'src/utils/formatDate'
import { type ReceivedOffer } from 'src/types'
import TableCellPopover from 'src/components/TableCellPopover'
import classes from 'src/components/Table/Table.module.css'

export type ReceivedOffersCustomTableBodyProps = {
  items: ReceivedOffer[]
}

export function CustomTableBody(props: ReceivedOffersCustomTableBodyProps) {
  return (
    <>
      <Table.Tbody>
        {props.items.map((offer) => {
          const supplier = offer.supplier
          const createdAtWithTime = offer.createdAt ? formatUTCDate(offer.createdAt, 'dd/MM/yyyy HH:mm') : ''
          const id = nanoid()

          return (
            <Table.Tr key={id}>
              <Table.Td>
                <Text inherit style={{ cursor: 'pointer' }}>
                  <Tooltip inline label={createdAtWithTime}>
                    <Text component="span" inherit>{offer.date}</Text>
                  </Tooltip>
                </Text>
              </Table.Td>
              <Table.Td>
                {offer.deliveryDate ? formatUTCDate(offer.deliveryDate, 'dd/MM/yyyy') : 'Sem data definida'}
              </Table.Td>
              <Table.Td>{offer.requester ? offer.requester.name : 'Não informado'}</Table.Td>
              <Table.Td>
                {supplier.name && supplier.phone ? (
                  <Button
                    p="0"
                    component={Link}
                    to={`/mensagens/${supplier.contactPhone}`}
                    variant="transparent"
                    className={classes.tableCell}
                  >
                    <TableCellPopover dropdown={<Text size="sm">{supplier.name}</Text>}>
                      <Box className={classes.tableCell}>
                        <Text truncate="end" inherit ta="start">
                          {supplier.name}
                        </Text>
                        <Text truncate="end" inherit ta="start" c="black" fw="normal">
                          {supplier.phone}
                        </Text>
                      </Box>
                    </TableCellPopover>
                  </Button>
                ) : undefined}
              </Table.Td>
              <Table.Td>{offer.sku}</Table.Td>
              <Table.Td ta="center">{offer.volume}</Table.Td>
              <Table.Td style={offer.priceExceedsFixedPrice ? { color: 'red' } : {}}>{offer.price}</Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </>
  )
}
