import { DEFAULT_PRODUCER_RECEIVING_DAYS } from 'src/constants/producers'
import { ProducerReceivingDays, SUPPLIER_TYPE } from 'src/types'
import { removeNonDigit } from 'src/utils/parseBrazilianPhones'
import * as yup from 'yup'

export const SUPPLIER_TYPES = [
  {
    label: 'Representante',
    value: SUPPLIER_TYPE.MIDDLEMAN,
  },
  {
    label: 'Representado',
    value: SUPPLIER_TYPE.REPRESENTED_SUPPLIER,
  },
  {
    label: 'Fornecedor',
    value: SUPPLIER_TYPE.SUPPLIER,
  },
]

export const dayNumberToName: Record<ProducerReceivingDays, string> = {
  '0': 'Domingo',
  '1': 'Segunda',
  '2': 'Terça',
  '3': 'Quarta',
  '4': 'Quinta',
  '5': 'Sexta',
  '6': 'Sábado',
}

export const supplierSchema = yup.object().shape({
  type: yup
    .mixed<SUPPLIER_TYPE>()
    .oneOf(Object.values(SUPPLIER_TYPE))
    .required('O campo "Tipo de fornecedor" é obrigatório.'),
  middlemanId: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: SUPPLIER_TYPE) => type === SUPPLIER_TYPE.REPRESENTED_SUPPLIER,
      then: (schema) => schema.nullable().required('Você precisa fornecer um representante para esse fornecedor.'),
      otherwise: (schema) => schema.nullable(),
    }),
  phone: yup
    .string()
    .matches(/^\+?55 \d{2} \d{8,9}$/, {
      excludeEmptyString: true,
      message: 'O campo "WhatsApp" precisa ser um número válido',
    })
    .test('duplicated_phone', 'Já existe um fornecedor com esse número', (value, { options }) => {
      if (!value) return true

      const currentSupplierPhone: string | undefined = options.context!.currentSupplierPhone
      const currentWhatsappNumbers: string[] = options.context!.currentWhatsappNumbers
      const isSame = currentWhatsappNumbers
        .filter((phone) => phone !== currentSupplierPhone)
        .includes(removeNonDigit(value))

      if (isSame) {
        return false
      }

      return true
    })
    .when('type', {
      is: (type: SUPPLIER_TYPE) => type !== SUPPLIER_TYPE.REPRESENTED_SUPPLIER,
      then: (schema) => schema.required('O campo "WhatsApp" é obrigatório.'),
      otherwise: (schema) => schema,
    }),
  code: yup
    .string()
    .required('O campo "Código" é obrigatório.')
    .min(3, 'O campo "Código" deve ter pelo menos 3 caracteres.')
    .test('duplicated_code', 'Já existe um fornecedor com esse código', (value, { options }) => {
      if (!value) return true

      const isEditing: boolean = options.context!.isEditing
      const currentSuppliersCodes: string[] = options.context!.currentSuppliersCodes
      const isSame = currentSuppliersCodes.includes(value.toUpperCase())

      if (!isEditing && isSame) {
        return false
      }

      return true
    }),
  name: yup.string().required('O campo "Nome" é obrigatório.'),
  taxpayerIdentificationNumber: yup.string().test('len', 'O campo "CPF/CNPJ" é inválido', (value) => {
    if (!value) return true

    const unmaskedValue = value.replace(/[^\d]/g, '')
    return unmaskedValue.length === 11 || unmaskedValue.length === 14
  }),
  address: yup.object().shape({
    zipCode: yup.string().optional(),
    state: yup.string().nullable().optional(),
    city: yup.string().optional(),
    neighborhood: yup.string().optional(),
    street: yup.string().optional(),
    number: yup.string().optional(),
    complement: yup.string().optional(),
  }),
  observations: yup.string().optional(),
  skuCodesReference: yup.array(yup.string().required()).required(),
  fixedPrices: yup
    .array(
      yup.object().shape({
        sku: yup.string().required(),
        fixedPrice: yup.number().required(),
      }),
    )
    .required(),
  rejectionRate: yup.number().required(),
  hasInfiniteStock: yup.boolean().required(),
  receivingDays: yup.array(yup.string().required().oneOf(DEFAULT_PRODUCER_RECEIVING_DAYS)).required(),
  minimumOrderPrice: yup.number().required(),
  tags: yup.array(yup.string().uuid().required()),
})
