import { Avatar, Box, Card, Flex, Group, Text } from '@mantine/core'
import { Icon as TablerIcon, IconFileText, IconMicrophone, IconPhoto } from '@tabler/icons-react'
import { isToday, isYesterday } from 'date-fns'
import { Link } from 'react-router-dom'
import { MESSAGE_TYPE, MessageFromBackend } from 'src/types'
import { formatDate } from 'src/utils/formatDate'
import { getInitials } from 'src/utils/getInitials'
import { getStatusIcon } from '../Chat/statuses'

interface ICardMessageContactProps {
  name: string
  lastMessage?: MessageFromBackend
  unread?: number
  phone: string
}

export default function CardMessageContact(props: ICardMessageContactProps) {
  const StatusIcon = getStatusIcon(props.lastMessage?.status)
  const MessageTypeIcon = makeLastMessageIcon(props.lastMessage?.type)
  const readUnreadColor = props.unread ? '#000000' : '#595959'

  return (
    <Link to={`/mensagens/${props.phone}`} style={{ width: '100%' }}>
      <Card radius="md" shadow="md">
        <Flex gap="md" w="100%">
          <Avatar h={48} w={48} color="cyan" radius="999" alt={props.name}>
            {getInitials(props.name)}
          </Avatar>
          <Box flex={1} style={{ overflow: 'hidden' }}>
            <Text truncate fz={17} fw={props.unread ? 600 : 400} c={readUnreadColor}>
              {props.name}
            </Text>
            {props.lastMessage && (
              <Group gap={6} wrap="nowrap">
                {!!StatusIcon && props.lastMessage.fromOrg && <StatusIcon />}
                {!!MessageTypeIcon && <MessageTypeIcon size={16} color={readUnreadColor} />}
                <Text flex={1} truncate fz={14} fw={props.unread ? 600 : 400} c={readUnreadColor}>
                  {makeLastMessageText(props.lastMessage.type, props.lastMessage.content)}
                </Text>
              </Group>
            )}
          </Box>
          <Flex align="flex-end" direction="column" justify={'space-between'} gap={'xs'}>
            {!!props.lastMessage?.createdAt && (
              <Text fz={12} c={'#8696A0'}>
                {formatLastMessageTime(props.lastMessage.createdAt)}
              </Text>
            )}
            {!!props.unread && (
              <Flex align={'center'} justify="center" bg={'#4fc877'} w={16} h={16} style={{ borderRadius: 16 }}>
                <Text c={'#ffffff'} fz={12}>
                  {props.unread}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Card>
    </Link>
  )
}

const MESSAGE_TEXT_TYPE_MAPPER: Record<string, string> = {
  [MESSAGE_TYPE.AUDIO]: 'Áudio',
  [MESSAGE_TYPE.IMAGE]: 'Foto',
  [MESSAGE_TYPE.VIDEO]: 'Vídeo',
  [MESSAGE_TYPE.APPLICATION]: 'Documento',
}

function makeLastMessageText(type?: MESSAGE_TYPE, content?: string | null) {
  if (!type) return content

  return MESSAGE_TEXT_TYPE_MAPPER[type] || content
}

const MESSAGE_ICON_TYPE_MAPPER: Record<string, TablerIcon> = {
  [MESSAGE_TYPE.AUDIO]: IconMicrophone,
  [MESSAGE_TYPE.IMAGE]: IconPhoto,
  [MESSAGE_TYPE.VIDEO]: IconPhoto,
  [MESSAGE_TYPE.APPLICATION]: IconFileText,
}

function makeLastMessageIcon(type?: MESSAGE_TYPE) {
  if (!type) return

  return MESSAGE_ICON_TYPE_MAPPER[type]
}

function formatLastMessageTime(date: Date) {
  if (isToday(date)) {
    return formatDate(date, 'HH:mm')
  }

  if (isYesterday(date)) {
    return 'Ontem'
  }

  return formatDate(date, 'dd/MM/yyyy')
}
