import { useCallback } from 'react'

import { useQueryParams } from 'src/hooks/useQueryParams'
import type { DateValue } from '@mantine/dates'

export function useManageQueryParams() {
  const { getQueryParams, setQueryParams } = useQueryParams()
  const queryParams = getQueryParams()

  function setQueryDateParamByField(field: { name: string; onChange: (value: unknown) => void }, values: DateValue[]) {
    const dates = values.map((date) => (date ? date.toJSON() : '')).filter(Boolean)
    setQueryParams({ [field.name]: dates.length ? dates : '' })
    field.onChange(values)
  }

  const getQueryArrayParam = useCallback((param: string) => {
    const search = queryParams.get(param)

    if (!search) {
      return null
    }

    const params = search.split(',').filter(Boolean)

    if (!params.length) {
      return null
    }

    return params
  }, [])

  return { queryParams, setQueryDateParamByField, getQueryArrayParam }
}
