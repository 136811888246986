import { useCallback, useMemo, useState } from 'react'
import {
  CheckIcon,
  Combobox,
  Group,
  Loader,
  Pill,
  PillsInput,
  PillsInputProps,
  ScrollArea,
  useCombobox,
} from '@mantine/core'

const DEFAULT_MAX_DISPLAYED_VALUES = 2

const CREATE_VALUE = '$create'

export type CustomMultiSelectOption = {
  label: string
  value: string
  preventDelete?: boolean
  color?: string
}

export type CustomMultiSelectProps = {
  options: CustomMultiSelectOption[]
  maxDisplayedValues?: number
  placeholder?: string
  inputProps?: PillsInputProps
  hidePills?: boolean
  value: CustomMultiSelectOption[]
  onChange?: (opts: CustomMultiSelectOption[]) => void
  limit?: number
  // new properties
  isLoading?: boolean
  onOpenedChange?(val: boolean): void
  creatable?: boolean
  onCreateValue?: (val: string) => void | Promise<void> | CustomMultiSelectOption | Promise<CustomMultiSelectOption>
  error?: string
  label?: string
}

export function CustomMultiSelect(props: CustomMultiSelectProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    onOpenedChange: (val) => props.onOpenedChange?.(val),
  })

  const MAX_DISPLAYED_VALUES = useMemo(
    () => props.maxDisplayedValues ?? DEFAULT_MAX_DISPLAYED_VALUES,
    [props.maxDisplayedValues],
  )

  const [search, setSearch] = useState('')

  const toggleSelectValue = useCallback(
    (option: CustomMultiSelectOption) => {
      const options = props.value.includes(option)
        ? props.value.filter((v) => v.value !== option.value)
        : [...props.value, option]

      props.onChange?.(options)
      setSearch('')
    },
    [props],
  )

  const handleValueSelect = useCallback(
    async (val: string) => {
      if (val === CREATE_VALUE) {
        const option = await props.onCreateValue?.(search)

        if (option && option.value && option.label) {
          toggleSelectValue(option)
        }
        return
      }

      const option = props.options.find((opt) => opt.value === val)

      if (!option) return

      toggleSelectValue(option)
    },
    [props, search, toggleSelectValue],
  )

  const handleValueRemove = useCallback(
    (val: CustomMultiSelectOption) => {
      const options = props.value.filter((v) => v.value !== val.value)

      props?.onChange?.(options)
    },
    [props],
  )

  const pills = useMemo(() => {
    return props.value
      .slice(0, MAX_DISPLAYED_VALUES >= props.value.length ? MAX_DISPLAYED_VALUES : MAX_DISPLAYED_VALUES - 1)
      .map((item) => (
        <Pill key={item.value} withRemoveButton onRemove={() => handleValueRemove(item)}>
          {item.label}
        </Pill>
      ))
  }, [MAX_DISPLAYED_VALUES, handleValueRemove, props.value])

  const options = useMemo(() => {
    return props.options
      .filter((item) => {
        const searchLowerCase = search.trim().toLowerCase()
        const label = item.label.toLowerCase()
        const value = item.value.toLowerCase()

        return label.includes(searchLowerCase) || value.includes(searchLowerCase)
      })
      .map((item) => (
        <Combobox.Option
          value={item.value}
          key={item.value}
          active={props.value.includes(item)}
          disabled={!!props.limit && props.value.length >= props.limit && !props.value.includes(item)}
        >
          <Group gap="xs" justify="space-between" wrap="nowrap">
            <span>{item.label}</span>
            {props.value.includes(item) ? <CheckIcon color="gray" size={12} /> : null}
          </Group>
        </Combobox.Option>
      ))
  }, [props.options, search, props.value, props.limit])

  const exactOptionMatch = props.options.some((item) => item.label === search)

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.DropdownTarget>
        <PillsInput
          label={props.label}
          rightSection={props.isLoading ? <Loader size={16} /> : <Combobox.Chevron />}
          {...props.inputProps}
          onClick={() => combobox.openDropdown()}
          error={props.error}
        >
          <Pill.Group>
            {!!props.value.length && !props.hidePills && (
              <>
                {pills}
                {props.value.length > MAX_DISPLAYED_VALUES && (
                  <Pill>+{props.value.length - (MAX_DISPLAYED_VALUES - 1)}</Pill>
                )}
              </>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={props.placeholder}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault()
                    handleValueRemove(props.value[props.value.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown mah={250}>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="scroll">
            {props.isLoading && <Combobox.Empty>Carregando....</Combobox.Empty>}
            {!!options.length && !props.isLoading && options}
            {props.creatable && !exactOptionMatch && !props.isLoading && search.trim().length > 0 && (
              <Combobox.Option value={CREATE_VALUE}>+ Criar {search}</Combobox.Option>
            )}
            {!options.length && !props.isLoading && !props.creatable && (
              <Combobox.Empty>Nada encontrado</Combobox.Empty>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
