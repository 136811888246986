import { useMemo, useRef, useEffect, useCallback, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useQuery } from '@tanstack/react-query'
import { Card, Box, Title, Button, Space, Center, Text, Flex, Grid, ActionIcon, Collapse } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { eachDayOfInterval, endOfDay, isSameDay, subMonths, differenceInCalendarMonths } from 'date-fns'
import { UTCDate } from '@date-fns/utc'
import { IconFilter, IconFileDownload, IconRefresh } from '@tabler/icons-react'
import { object, string, date, InferType, array } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { useDistributor } from 'src/providers/Distributor'
import { useSuppliers } from 'src/hooks/useSuppliers'
import { useSKUs } from 'src/hooks/useSKUs'
import { CustomTable } from './Table'
import MultiSelect from 'src/components/MultiSelect'
import { sanitizeReceivedOffersToCsv } from 'src/utils/prepareDataToCsvExport'
import { OrderFromBackend, Producer, ReceivedOffer, SKU } from 'src/types'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { parseInfiniteStock } from 'src/utils/products/volume'
import formatPrice from 'src/utils/formatPrice'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { enforceUTCDate, formatDate } from 'src/utils/formatDate'
import { usePaginateArray } from 'src/hooks/usePagination'
import { getAllOffers } from 'src/requests/firebase/offers/getAllOffers'
import { CSV_NAME } from 'src/constants/csv'
import { PAGE_SIZE } from 'src/constants/pagination'

import type { DateValue } from '@mantine/dates'
import styles from 'src/components/SupplyBySKU/SupplyBySKU.module.css'
import { useManageQueryParams } from 'src/hooks/useManageQueryParams'

const schema = object({
  createdAt: array(date())
    .required()
    .length(2, 'Selecione uma data de oferta válida')
    .ensure()
    .test({
      name: 'is-within-60-days-range',
      test: ([start, end]: any, { createError }) => {
        if (differenceInCalendarMonths(end, start) > 1) {
          return createError({ path: 'createdAt', message: 'O intervalo entre as datas deve ser no máximo 60 dias' })
        }

        return true
      },
    }),
  deliveryDate: array().of(date()),
  suppliers: array().of(string()),
  skus: array().of(string()),
})

type Schema = InferType<typeof schema>

export function ReceivedOffersTable() {
  const { distributor } = useDistributor()
  const { labelValueSKUs } = useSKUs()
  const { suppliers, labelValueSuppliersWithId } = useSuppliers()
  const [filterOpened, { toggle: toggleFilter }] = useDisclosure(true)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const cardPadding = isMobile ? 'md' : 'xl'
  const cardRef = useRef<HTMLDivElement>(null)
  const { queryParams, getQueryArrayParam, setQueryDateParamByField } = useManageQueryParams()
  const { setQueryParams } = useQueryParams()

  // query params
  const queryPage = queryParams.get('page') ? Number(queryParams.get('page')) : 1
  const queryCreatedAt = useMemo(() => {
    const param = getQueryArrayParam('deliveryDate')

    if (param) {
      return param.map((date) => new Date(date))
    }

    const maxDate = new Date()
    maxDate.setUTCHours(0, 0, 0, 0)
    const minDate = subMonths(maxDate, 1)
    const values = [minDate, maxDate]
    setQueryParams({ createdAt: values.map((date) => date.toJSON()) })

    return values
  }, [])

  const queryDeliveryDate = useMemo(
    () => (getQueryArrayParam('deliveryDate') ? getQueryArrayParam('deliveryDate')!.map((date) => new Date(date)) : []),
    [getQueryArrayParam],
  )
  const suppliersQuery = useMemo(
    () => (getQueryArrayParam('suppliers') ? getQueryArrayParam('suppliers')! : []),
    [getQueryArrayParam],
  )

  const [createdAt, setCreatedAt] = useState<Date[]>(queryCreatedAt)
  const [deliveryDate, setDeliveryDate] = useState<Date[]>(queryDeliveryDate)
  const [formSuppliers, setFormSuppliers] = useState<string[]>(suppliersQuery)
  const querySKUs = useMemo(() => (getQueryArrayParam('skus') ? getQueryArrayParam('skus')! : []), [getQueryArrayParam])
  const [skus, setSKUs] = useState<string[]>(querySKUs)

  const defaultValues = {
    createdAt,
    deliveryDate,
    suppliers: formSuppliers,
    skus,
  }

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const isEnabled = form.formState.isValid && createdAt.length === 2

  // queries
  const {
    data: offers,
    isFetching,
    refetch: refetchOffers,
  } = useQuery({
    queryKey: [
      'offers',
      distributor!.distributorId,
      { createdAt: defaultValues.createdAt, suppliers: defaultValues.suppliers },
    ],
    queryFn: () =>
      getAllOffers({
        distributorId: distributor!.distributorId,
        page: activePage,
        createdAt: defaultValues.createdAt,
        suppliers: defaultValues.suppliers,
      }),
    enabled: isEnabled,
  })

  // filters
  const filterFlattenOffersBySKUs = useCallback((offers: OrderFromBackend[], skus: SKU[], querySKUs: string[]) => {
    const names = skus.filter((sku) => querySKUs.includes(sku.Código)).map((sku) => sku.SKU)
    return offers.map((offer) => {
      const products = offer.products.filter((product) => names.includes(product.sku))

      return {
        ...offer,
        products,
      }
    })
  }, [])

  // NOTE: Firebase's query limitations with `OR` operators can cause unexpected results.
  // Additionally, business logic dictates that each SKU in an offer appears as a row in the table,
  // and these rows must be filtered by either `deliveryDate` or `validUntil`.
  // As this filtering logic is closely tied to UI behavior, it is intentionally performed on the front-end
  // rather than the back-end to maintain consistency and simplify server logic.
  const filterFlattenOffersByDeliveryDate = useCallback((offers: OrderFromBackend[], date: Date[]) => {
    const [startDate, endDate] = date
    const parsedStartDate = new Date(startDate)
    const parsedEndDate = endDate ? new Date(endDate) : new Date(startDate)
    parsedStartDate.setUTCHours(0)
    parsedEndDate.setUTCHours(0)

    return offers.filter((offers) => {
      if (offers.validUntil) {
        const validUntil = new Date(offers.validUntil)
        validUntil.setUTCHours(0)

        return validUntil >= parsedStartDate && validUntil <= endOfDay(parsedEndDate)
      }

      const deliveryDate = new Date(offers.deliveryDate)
      deliveryDate.setUTCHours(0)

      return deliveryDate >= parsedStartDate && deliveryDate <= endOfDay(parsedEndDate)
    })
  }, [])

  // memos
  // NOTE: Filtering is optimized by applying filters on smaller set of rows received from the back-end before these rows are flattened into
  // multiple rows to ensure each SKU appears as a distinct row in the table.
  const offersWithProducerName: ReceivedOffer[] = useMemo(() => {
    const distributorSKUs = distributor?.skus ?? []
    let flattenOffers: OrderFromBackend[] | ReceivedOffer[] = offers?.records ?? []

    if (skus && skus.length) {
      flattenOffers = filterFlattenOffersBySKUs(flattenOffers, distributorSKUs, skus)
    }

    if (deliveryDate && deliveryDate.length) {
      flattenOffers = filterFlattenOffersByDeliveryDate(flattenOffers, deliveryDate)
    }

    flattenOffers = makeFlattenOffers(flattenOffers, suppliers, distributorSKUs, true)

    return flattenOffers
  }, [suppliers, distributor?.skus, offers?.records, deliveryDate, skus])

  const csvOffers = useMemo(() => {
    return makeOrdersWithExpirationDate(offersWithProducerName)
  }, [offersWithProducerName])

  const { activePage, totalPages, onChangePage, paginated } = usePaginateArray(offersWithProducerName, PAGE_SIZE)

  useQuery({
    queryKey: ['offers', distributor!.distributorId, { page: activePage }],
    queryFn: () => {
      setQueryParams({ page: activePage })

      return true
    },
    enabled: !!distributor!.distributorId,
  })

  const csvData = sanitizeReceivedOffersToCsv(csvOffers)

  useEffect(() => {
    onChangePage(queryPage)
  }, [])

  // NOTE: Validate form everytime its values changes
  useEffect(() => {
    const subscription = form.watch(() => {
      form.trigger()
    })

    return () => subscription.unsubscribe()
  }, [form.watch])

  return (
    <Card padding={cardPadding} shadow="md" radius="md" className={styles.card} ref={cardRef}>
      <Box className={styles.fixed_table_name} w={cardRef.current?.offsetWidth ?? 0} top={80}>
        <Box className={styles.header__title}>
          <Title order={3}>Ofertas recebidas</Title>
          <Box display={'flex'} className={styles.header__actions}>
            <ActionIcon
              onClick={toggleFilter}
              loaderProps={{ type: 'dots' }}
              variant={filterOpened ? 'light' : 'subtle'}
              size="lg"
              title="Filtro"
            >
              <IconFilter size={20} />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                onChangePage(1)
                refetchOffers()
              }}
              loading={isFetching}
              loaderProps={{ type: 'dots' }}
              variant="filled"
              title="Recarregar"
            >
              <IconRefresh size={20} />
            </ActionIcon>
            <Flex align="center" justify="space-between" px={25}>
              <Flex columnGap={16}>
                <CSVLink filename={CSV_NAME} data={csvData}>
                  <Button size="sm" variant="default" radius="sm">
                    <IconFileDownload strokeWidth={1.5} size={18} style={{ marginRight: isMobile ? 0 : 12 }} />
                    {!isMobile && 'Exportar'}
                  </Button>
                </CSVLink>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box mt={30} className={styles.header}>
        <Collapse in={filterOpened}>
          <Grid mt={8}>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="createdAt"
                render={({ field, fieldState }) => (
                  <DatePickerInput
                    allowSingleDateInRange
                    label="Data da oferta"
                    placeholder="Selecione uma data"
                    locale="pt-br"
                    valueFormat="DD, MMM/YY"
                    type="range"
                    clearable
                    value={field.value as [DateValue, DateValue]}
                    error={fieldState.error?.message}
                    onChange={(values) => {
                      const filtered = values.filter(Boolean)
                      setCreatedAt(filtered as Date[])
                      setQueryDateParamByField(field, filtered)
                    }}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="deliveryDate"
                render={({ field, fieldState }) => (
                  <DatePickerInput
                    allowSingleDateInRange
                    label="Data de entrega"
                    placeholder="Selecione uma data"
                    locale="pt-br"
                    valueFormat="DD, MMM/YY"
                    type="range"
                    clearable
                    value={field.value as [DateValue, DateValue]}
                    error={fieldState.error?.message}
                    onChange={(values) => {
                      const filtered = values.filter(Boolean)
                      setDeliveryDate(filtered as Date[])
                      setQueryDateParamByField(field, filtered)
                    }}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="suppliers"
                render={({ field }) => (
                  <MultiSelect
                    name="suppliers"
                    placeholder="Escolha um ou mais fornecedores"
                    label="Fornecedores"
                    data={labelValueSuppliersWithId}
                    searchable
                    clearable
                    checkIconPosition="right"
                    value={field.value as string[]}
                    onChange={(values) => {
                      setFormSuppliers(values)
                      field.onChange(values)
                    }}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="skus"
                render={({ field }) => (
                  <MultiSelect
                    name="skus"
                    placeholder="Escolha um ou mais SKUs"
                    label="SKUs"
                    data={labelValueSKUs}
                    searchable
                    clearable
                    checkIconPosition="right"
                    value={field.value as string[]}
                    onChange={(values) => {
                      field.onChange(values)
                      setSKUs(values)
                    }}
                  />
                )}
              />
            </Grid.Col>
          </Grid>
        </Collapse>
      </Box>
      <Space h="lg" />
      {!offersWithProducerName?.length && !isFetching ? (
        <Center>
          <Text>Você ainda não tem ofertas recebidas</Text>
        </Center>
      ) : (
        <CustomTable items={paginated} activePage={activePage} onChangePage={onChangePage} totalPages={totalPages} />
      )}
    </Card>
  )
}

function makeOrdersWithExpirationDate(orders: ReceivedOffer[]) {
  return orders.flatMap((order, index) => {
    if (!order.validUntil) return [order]

    const days = eachDayOfInterval({
      start: new UTCDate(enforceUTCDate(new Date())),
      end: new UTCDate(order.validUntil),
    })

    const filteredDays = days.filter((day) => {
      const alreadyExists = orders.some((o, idx) => {
        const sameSupplierId = o.supplierId === order.supplierId
        const sameDeliveryDate = isSameDay(day.toISOString(), o.deliveryDate)
        const notSent = !o.sentAt
        const notSameOrder = index !== idx

        return sameSupplierId && sameDeliveryDate && notSent && notSameOrder
      })

      return !alreadyExists
    })

    return filteredDays.map((day) => ({
      ...order,
      deliveryDate: day,
    }))
  })
}

function makeFlattenOffers(
  orders: OrderFromBackend[],
  suppliers: Producer[],
  skus?: SKU[],
  showExpirationDate?: boolean,
): ReceivedOffer[] {
  return orders.flatMap((offer) => {
    const supplier = suppliers.find((s) => s.id === offer.supplierId || (s.phone && s.phone === offer.phone))
    const middleman = suppliers.find((s) => s.id === supplier?.middlemanId)
    const date = offer.createdAt ? formatDate(offer.createdAt, 'dd/MM/yyyy') : '-' // FIXME: set it to BRT
    const phone = supplier?.phone || middleman?.phone || ''

    return offer.products.map((product) => ({
      id: offer.id,
      supplier: {
        name: supplier?.name ?? 'Não encontrado',
        phone: formatBrazilianNumber(phone).slice(3),
        contactPhone: phone,
      },
      createdAt: offer.createdAt,
      date,
      sku: product.sku,
      category: skus?.find((sku) => sku['SKU'] === product.sku)?.['Categoria'],
      volume: parseInfiniteStock(product.volume, {
        suffix: product.unit,
      }),
      deliveryDate: offer.validUntil && showExpirationDate ? offer.validUntil : offer.deliveryDate,
      price: formatPrice(product.price),
      priceExceedsFixedPrice: product.priceExceedsFixedPrice,
      unit: product.unit,
      requester: offer.requester,
      validUntil: offer.validUntil,
      supplierId: offer.supplierId,
      sentAt: offer.sentAt,
    }))
  })
}
