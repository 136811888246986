import { useMemo, useState, useRef, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import {
  Text,
  Card,
  Box,
  Title,
  ActionIcon,
  Space,
  Collapse,
  Button,
  Center,
  Flex,
  Grid,
  Loader,
  Select,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { IconFilter, IconFileDownload } from '@tabler/icons-react'
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks'
import { useQuery } from '@tanstack/react-query'
import { object, string, date, InferType, array } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { useDistributor } from 'src/providers/Distributor'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { useSuppliers } from 'src/hooks/useSuppliers'
import { useMiddlemen } from 'src/hooks/useMiddlemen'
import { useSKUs } from 'src/hooks/useSKUs'
import { sanitizeSentOrdersToCsv } from 'src/utils/prepareDataToCsvExport'
import getOrdersWithSuppliers from 'src/utils/getOrdersWithSuppliers'
import MultiSelect from 'src/components/MultiSelect'
import { ORDER_STATUS } from 'src/utils/decideOrderStatus'
import ExportSAPModal from 'src/components/SentOrdersTable/ExportOrders/ExportSAPModal'
import { getSentOrders } from 'src/requests/orders/getSentOrders'
import { CustomTable } from 'src/components/SentOrdersTable/Table'
import { useManageQueryParams } from 'src/hooks/useManageQueryParams'
import type { DateValue } from '@mantine/dates'
import { CSV_NAME } from 'src/constants/csv'
import { PAGE_SIZE } from 'src/constants/pagination'
// TODO: Create a separated file to style generic filters
import styles from 'src/components/SupplyBySKU/SupplyBySKU.module.css'
import { type OrderFromBackend } from 'src/types'

const schema = object({
  sentAt: array().of(date()),
  deliveryDate: array().of(date()),
  buyers: array().of(string()),
  suppliers: array().of(string()),
  skus: array().of(string()),
  status: string(),
})

type Schema = InferType<typeof schema>
type SelectOption = {
  label: string
  value: string
}

function getLabelValueBuyers(orders: OrderFromBackend[]) {
  const seen = new Set<string>()

  return orders.reduce((acc: SelectOption[], order: OrderFromBackend) => {
    const buyerId = order.buyer?.id

    if (buyerId && !seen.has(buyerId)) {
      seen.add(buyerId)
      acc.push({ label: order.buyer!.name, value: order.buyer!.id })
    }

    return acc
  }, [])
}

export default function SentOrdersTable() {
  const { distributor } = useDistributor()
  const { middlemen } = useMiddlemen()
  const { labelValueSKUs } = useSKUs()
  const { labelValueSuppliersWithId } = useSuppliers()
  const [scroll] = useWindowScroll()
  const cardRef = useRef<HTMLDivElement>(null)
  const [openedSAP, { open: openSAP, close: closeSAP }] = useDisclosure()
  const [filterOpened, { toggle: toggleFilter }] = useDisclosure(true)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const { setQueryParams } = useQueryParams()
  const { queryParams, getQueryArrayParam, setQueryDateParamByField } = useManageQueryParams()
  const cardPadding = isMobile ? 'md' : 'xl'

  // query params
  const queryPage = queryParams.get('page') ? Number(queryParams.get('page')) : 1
  const status = queryParams.get('status') ? String(queryParams.get('status')) : undefined
  const sentAt = getQueryArrayParam('sentAt') ? getQueryArrayParam('sentAt')!.map((date) => new Date(date)) : []
  const deliveryDate = getQueryArrayParam('deliveryDate')
    ? getQueryArrayParam('deliveryDate')!.map((date) => new Date(date))
    : []
  const buyers = getQueryArrayParam('buyers') ? getQueryArrayParam('buyers')! : []
  const suppliers = getQueryArrayParam('suppliers') ? getQueryArrayParam('suppliers')! : []
  const skus = getQueryArrayParam('skus') ? getQueryArrayParam('skus')! : []

  // states
  const [page, setPage] = useState(queryPage)
  const defaultValues = {
    sentAt,
    deliveryDate,
    buyers,
    suppliers,
    skus,
    status,
  }

  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const formValues = form.getValues()

  // queries
  const { data, isLoading } = useQuery({
    queryKey: ['orders', 'sent', { ...formValues }],
    queryFn: () => {
      return getSentOrders({
        distributorId: distributor!.distributorId,
        page,
        size: 0,
        ...formValues,
      })
    },
    enabled: !!distributor!.distributorId,
  })

  useQuery({
    queryKey: ['orders', 'sent', { page }],
    queryFn: () => {
      setQueryParams({ page })

      return true
    },
    enabled: !!distributor!.distributorId,
  })

  // memos
  const ordersWithSuppliers = useMemo(
    () => getOrdersWithSuppliers(data?.records, distributor, { key: 'sentAt', sort: 'desc' }),
    [data?.records, distributor],
  )
  const paginated = useMemo(() => {
    if (ordersWithSuppliers.length <= PAGE_SIZE) {
      return ordersWithSuppliers
    }

    let offset = (page - 1) * PAGE_SIZE

    // XXX: Set offset to 0 when offset is negative
    if (offset < 0) {
      offset = 0
    }

    return ordersWithSuppliers.slice(offset, page * PAGE_SIZE)
  }, [ordersWithSuppliers, page])
  const labelValueBuyers = useMemo(() => getLabelValueBuyers(data?.records ?? []), [data?.records])

  // TODO: Use usePaginateArray hook
  const totalPages = useMemo(() => {
    if (!data || !data.records.length) {
      return 1
    }

    const total = Math.ceil(data.records.length / PAGE_SIZE) || 1

    return total
  }, [data?.records])

  // misc
  const statusLabelWithValues = Object.entries(ORDER_STATUS).map(([value, label]) => ({
    value,
    label,
  }))
  const csvData = sanitizeSentOrdersToCsv(ordersWithSuppliers, distributor?.skus, middlemen)

  // TODO: Move flag to backend
  const hasSAPIntegration =
    import.meta.env?.VITE_FEAT_ORGS_WITH_SAP_INTEGRATION?.split(',').includes(String(distributor?.distributorId)) ??
    false

  useEffect(() => {
    // NOTE: Reset page everytime form data changes
    const subscription = form.watch(() => {
      setPage(1)
    })

    return () => subscription.unsubscribe()
  }, [form.handleSubmit, form.watch])

  return (
    <Card padding={cardPadding} shadow="md" radius="md" className={styles.card} ref={cardRef}>
      <Box className={styles.fixed_table_name} w={cardRef.current?.offsetWidth ?? 0} top={scroll.y > 0 ? 60 : 80}>
        <Box className={styles.header__title}>
          <Title order={3}>Pedidos enviados</Title>

          <Box display={scroll.y > 0 ? 'none' : 'flex'} className={styles.header__actions}>
            <ActionIcon
              onClick={toggleFilter}
              loaderProps={{ type: 'dots' }}
              variant={filterOpened ? 'light' : 'subtle'}
              size="lg"
              title="Filtro"
            >
              <IconFilter size={20} />
            </ActionIcon>
            {hasSAPIntegration && (
              <ExportSAPModal
                title="Exportar pedidos enviados"
                opened={openedSAP}
                onClose={closeSAP}
                filters={formValues}
              />
            )}
            <Flex align="center" justify="space-between" px={25}>
              <Flex columnGap={16}>
                {hasSAPIntegration && (
                  <Button onClick={openSAP} size="sm" variant="default" radius="sm">
                    <IconFileDownload strokeWidth={1.5} size={18} style={{ marginRight: isMobile ? 0 : 12 }} />
                    {!isMobile && 'Exportar SAP'}
                  </Button>
                )}
                <CSVLink filename={CSV_NAME} data={csvData}>
                  <Button size="sm" variant="default" radius="sm">
                    <IconFileDownload strokeWidth={1.5} size={18} style={{ marginRight: isMobile ? 0 : 12 }} />
                    {!isMobile && 'Exportar'}
                  </Button>
                </CSVLink>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box mt={30} className={styles.header}>
        <Collapse in={filterOpened}>
          <Grid mt={8}>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="sentAt"
                render={({ field, fieldState }) => (
                  <DatePickerInput
                    allowSingleDateInRange
                    label="Data do pedido"
                    placeholder="Selecione uma data"
                    locale="pt-br"
                    valueFormat="DD, MMM/YY"
                    type="range"
                    clearable
                    value={field.value as [DateValue, DateValue]}
                    error={fieldState.error?.message}
                    onChange={(values) => setQueryDateParamByField(field, values)}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="deliveryDate"
                render={({ field, fieldState }) => (
                  <DatePickerInput
                    allowSingleDateInRange
                    label="Data de entrega"
                    placeholder="Selecione uma data"
                    locale="pt-br"
                    valueFormat="DD, MMM/YY"
                    type="range"
                    clearable
                    value={field.value as [DateValue, DateValue]}
                    error={fieldState.error?.message}
                    onChange={(values) => setQueryDateParamByField(field, values)}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="buyers"
                render={({ field }) => (
                  <MultiSelect
                    name="buyers"
                    placeholder="Escolha um ou mais compradores"
                    label="Compradores"
                    data={labelValueBuyers}
                    searchable
                    clearable
                    checkIconPosition="right"
                    value={field.value as string[]}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="suppliers"
                render={({ field }) => (
                  <MultiSelect
                    name="suppliers"
                    placeholder="Escolha um ou mais fornecedores"
                    label="Fornecedores"
                    data={labelValueSuppliersWithId}
                    searchable
                    clearable
                    checkIconPosition="right"
                    value={field.value as string[]}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="skus"
                render={({ field }) => (
                  <MultiSelect
                    name="skus"
                    placeholder="Escolha um ou mais SKUs"
                    label="SKUs"
                    data={labelValueSKUs}
                    searchable
                    clearable
                    checkIconPosition="right"
                    value={field.value as string[]}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                base: 12,
                xs: 6,
              }}
            >
              <Controller
                control={form.control}
                name="status"
                render={({ field }) => (
                  <Select
                    name="status"
                    placeholder="Escolha um status"
                    label="Status"
                    data={statusLabelWithValues}
                    allowDeselect
                    checkIconPosition="right"
                    value={field.value}
                    onChange={(value) => {
                      setQueryParams({ status: value })
                      field.onChange(value)
                    }}
                  />
                )}
              />
            </Grid.Col>
          </Grid>
        </Collapse>
      </Box>
      <Space h="lg" />
      {!data || !data?.records?.length || isLoading ? (
        <Center>
          <Text>{isLoading ? <Loader /> : 'Você ainda não tem pedidos enviados'}</Text>
        </Center>
      ) : (
        <CustomTable activePage={page} onChangePage={setPage} totalPages={totalPages} items={paginated} />
      )}
    </Card>
  )
}
