import { Box, Button, Table, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconFileArrowRight } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import CancelModal from 'src/components/common/Modals/CancelModal'
import { useMiddlemen } from 'src/hooks/useMiddlemen'
import { useOrders } from 'src/providers/Orders'
import { OrderFromBackend, Producer } from 'src/types'
import { decideOrderStatus, ORDER_STATUS } from 'src/utils/decideOrderStatus'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import { formatUTCDate } from 'src/utils/formatDate'
import formatPrice from 'src/utils/formatPrice'
import { decideSupplierPhone } from 'src/utils/suppliers/decideSupplierPhone'
import Text from 'src/components/Text'
import TableCellPopover from 'src/components/TableCellPopover'
import classes from 'src/components/Table/Table.module.css'

type OrderWithProducerName = OrderFromBackend & {
  supplier?: Producer
}

export type SentOrdersTableBodyProps = {
  items: OrderWithProducerName[]
}

export function CustomTableBody({ items }: SentOrdersTableBodyProps) {
  const { onCancelOrder } = useOrders()

  const [isDeleteModalOpen, { open: openCancelModal, close: closeCancelModal }] = useDisclosure(false)
  const [orderIdToCancel, setOrderIdToCancel] = useState<string>('')

  const { middlemen } = useMiddlemen()

  const onCloseCancelModal = useCallback(() => {
    setOrderIdToCancel('')
    closeCancelModal()
  }, [closeCancelModal])

  const handleCancelOrder = useCallback(async () => {
    await onCancelOrder(orderIdToCancel)
    closeCancelModal()
  }, [orderIdToCancel, onCancelOrder])

  const handleClickCancel = useCallback(
    (id: string) => {
      setOrderIdToCancel(id)
      openCancelModal()
    },
    [openCancelModal],
  )

  return (
    <>
      <CancelModal
        show={isDeleteModalOpen}
        onCloseModal={onCloseCancelModal}
        title="Cancelar pedido"
        description="Tem certeza que deseja cancelar esse pedido?"
        handleCancel={handleCancelOrder}
      />
      <Table.Tbody>
        {items.map((order) => {
          const id = order.id
          const sentAt = order.sentAt ? formatUTCDate(order.sentAt, 'dd/MM/yyyy') : ''
          const sentAtWithTime = order.sentAt ? formatUTCDate(order.sentAt, 'dd/MM/yyyy HH:mm') : ''
          const deliveryDate = formatUTCDate(order.deliveryDate, 'dd/MM/yyyy')
          const buyerName = order.buyer ? order.buyer.name : 'Não informado'
          const name = order.supplier?.name
          const sentOrder = order.sentOrder
          const isCanceled = order.isCanceled
          const contactPhone = decideSupplierPhone(order, middlemen)
          const phone = formatBrazilianNumber(contactPhone).slice(3)
          const invoiceUrl = order?.invoiceUrl
          const skusInDraftOrder = sentOrder.map((order) => order.sku).join(', ') || 'Nenhum'
          const totalPrice = sentOrder.reduce((acc, item) => item.price * Number(item.desiredQty) + acc, 0)
          const price = formatPrice(totalPrice)

          return (
            <Table.Tr key={id}>
              <Table.Td>
                <Text inherit style={{ cursor: 'pointer' }}>
                  <Tooltip inline label={sentAtWithTime}>
                    <Box>{sentAt}</Box>
                  </Tooltip>
                </Text>
              </Table.Td>
              <Table.Td>{deliveryDate}</Table.Td>
              <Table.Td>{buyerName}</Table.Td>
              <Table.Td>
                {name && phone ? (
                  <Button
                    p="0"
                    component={Link}
                    to={`/mensagens/${contactPhone}`}
                    variant="transparent"
                    className={classes.tableCell}
                  >
                    <TableCellPopover dropdown={<Text size="sm">{name}</Text>}>
                      <Box className={classes.tableCell}>
                        <Text truncate="end" inherit ta="start">
                          {name}
                        </Text>
                        <Text truncate="end" inherit ta="start" c="black" fw="normal">
                          {phone}
                        </Text>
                      </Box>
                    </TableCellPopover>
                  </Button>
                ) : undefined}
              </Table.Td>
              <Table.Td>{skusInDraftOrder}</Table.Td>
              <Table.Td>{price}</Table.Td>
              <Table.Td align="center">
                {invoiceUrl && (
                  <Button p="0" component={Link} to={invoiceUrl} variant="transparent" target="_blank">
                    <IconFileArrowRight />
                  </Button>
                )}
              </Table.Td>
              <Table.Td align="center">{renderOrderStatus(order)}</Table.Td>
              <Table.Td>
                {isCanceled ? (
                  <Button w="100%" size="compact-sm" variant="light" disabled>
                    Cancelado
                  </Button>
                ) : (
                  <Button w="100%" size="compact-sm" variant="light" color="red" onClick={() => handleClickCancel(id)}>
                    Cancelar
                  </Button>
                )}
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </>
  )
}

function renderOrderStatus(order: OrderFromBackend): JSX.Element {
  const { isCanceled, confirmedByProducer } = order

  const status = decideOrderStatus(isCanceled, confirmedByProducer)

  const negativeStatuses = [ORDER_STATUS.CANCELED, ORDER_STATUS.REJECTED]

  const color = negativeStatuses.includes(status) ? 'red' : status === ORDER_STATUS.CONFIRMED ? 'green' : 'gray'

  const backgroundColor = isCanceled ? '#ffcccc' : confirmedByProducer ? '#ccffcc' : '#e0e0e0'

  const style = {
    width: 120,
    padding: '4px 16px',
    borderRadius: '4px',
    backgroundColor: backgroundColor,
    color: color,
    display: 'inline-block',
  }

  return (
    <Box style={style}>
      <Text size="sm" fw="bold">
        {status}
      </Text>
    </Box>
  )
}
