import 'chart.js/auto'
import { Flex, Text, Box, Group } from '@mantine/core'
import { Line, ChartProps } from 'react-chartjs-2'
import { ChartData } from 'chart.js/auto'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CustomMultiSelectOption } from 'src/components/common/Inputs/CustomMultiSelect'
import { useSKUs } from 'src/hooks/useSKUs'
import { generateHSLColor } from '../utils/colors'
import { INITIAL_CHECKBOXES_STATE, INITIAL_DATES_STATE, MAX_DATE } from '../SelectView/constants'
import { MultiSelectView } from '../SelectView'
import { useQuery } from '@tanstack/react-query'
import { ChartDates } from '../SelectView/types'
import { getMarketSummary } from 'src/requests/charts'
import { useDistributor } from 'src/providers/Distributor'
import { DatePickerInput } from '@mantine/dates'
import { ptBR } from 'date-fns/locale'

const config: Omit<ChartProps<'line'>, 'data'> = {
  type: 'line',
  height: '350px',
  width: '100%',
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          round: 'day',
          unit: 'day',
          displayFormats: {
            day: 'dd MMM',
          },
          tooltipFormat: 'dd MMM',
        },
        adapters: {
          date: {
            locale: ptBR,
          },
        },
      },
      y: {
        display: true,
        min: 0,
      },
    },
  },
}

export function PricePerUnitChart() {
  const [chartDates, setChartDates] = useState<ChartDates>(INITIAL_DATES_STATE)
  const { labelValueSKUs } = useSKUs()
  const [selectedSKUs, setSelectedSKUs] = useState<CustomMultiSelectOption[]>([])
  const [checkboxes, setCheckboxes] = useState<string[]>(INITIAL_CHECKBOXES_STATE)
  const { distributor } = useDistributor()

  const items = selectedSKUs.map((s) => s.value).join(',')

  const { data } = useQuery({
    initialData: [],
    queryKey: ['market-summary', chartDates, items],
    queryFn: ({ signal }) =>
      getMarketSummary(distributor!.distributorId, {
        params: {
          startDate: chartDates.startDate!,
          endDate: chartDates.endDate!,
          items,
        },
        signal,
      }),
    enabled: !!chartDates.endDate && !!chartDates.startDate && !!selectedSKUs.length && !!distributor?.distributorId,
  })

  const offers: ChartData<'line'>['datasets'] = useMemo(() => {
    if (!checkboxes.includes('offers')) return []

    return selectedSKUs.map((sku) => ({
      label: `${sku.label} - Oferta`,
      fill: false,
      backgroundColor: generateHSLColor(sku.value),
      borderColor: generateHSLColor(sku.value),
      data: data.map((item) => item[sku.value]?.averageOfferPrice ?? 0),
      borderDash: [5, 5],
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    }))
  }, [selectedSKUs, checkboxes, data])

  const purchases: ChartData<'line'>['datasets'] = useMemo(() => {
    if (!checkboxes.includes('purchases')) return []

    return selectedSKUs.map((sku) => ({
      label: `${sku.label} - Compra`,
      fill: false,
      backgroundColor: generateHSLColor(sku.value),
      borderColor: generateHSLColor(sku.value),
      data: data.map((item) => item[sku.value]?.averagePurchasePrice ?? 0),
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    }))
  }, [checkboxes, selectedSKUs, data])

  const chartData: ChartData<'line'> = useMemo(
    () => ({
      labels: data.map((item) => item.date),
      datasets: [...offers, ...purchases].sort((a, b) => a.label!.localeCompare(b.label!)),
    }),
    [offers, purchases, data],
  )

  const onChangeSKUs = useCallback((val: CustomMultiSelectOption[]) => {
    setSelectedSKUs(val)
  }, [])

  const onRemoveSKU = useCallback((val: CustomMultiSelectOption) => {
    setSelectedSKUs((current) => current.filter((v) => v.value !== val.value))
  }, [])

  // for resize purposes
  const ref = useRef<any>(null)

  useEffect(() => {
    window.addEventListener('resize', function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      ref.current?.resize?.()
    })
  }, [])

  return (
    <Box>
      <Flex gap="md">
        <Box flex={1}>
          <Group justify="space-between" mb="lg">
            <Text fw={500}>Preço/UN de medida</Text>

            <DatePickerInput
              onChange={(dates) => {
                const startDate = dates[0]
                const endDate = dates[1]

                setChartDates({
                  startDate,
                  endDate,
                })
              }}
              value={[chartDates.startDate, chartDates.endDate]}
              w={180}
              maxDate={MAX_DATE}
              allowSingleDateInRange
              valueFormat="DD MMM"
              locale="pt-br"
              clearable
              type="range"
              placeholder="Data"
            />
          </Group>
          <Box pos="relative">
            <Line ref={ref} {...config} data={chartData} />
          </Box>
        </Box>

        <MultiSelectView
          selected={selectedSKUs}
          handleRemove={onRemoveSKU}
          onChange={onChangeSKUs}
          options={labelValueSKUs}
          onChangeCheckboxes={setCheckboxes}
        />
      </Flex>
    </Box>
  )
}
