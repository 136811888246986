import { updateProducts } from 'src/requests/offer/products/updateProducts'

type UpdateOrderProductsPriceContext = {
  distributorId: string
  orderId: string
  newPrice: number
  sku: string
}

export default async function updateOrderProductsPrice({
  orderId,
  newPrice,
  sku,
}: UpdateOrderProductsPriceContext) {
  return await updateProducts(
    { orderId },
    {
      products: [
        {
          sku,
          price: newPrice,
        },
      ],
    },
  )
}
