import { api } from 'src/services/api'
import { Order } from 'src/types'

type Product = Partial<Order['products'][0]>

type Data = {
  products: Product[]
}

type Indexes = {
  orderId: string
}

export async function updateProducts({ orderId }: Indexes, data: Data) {
  const response = await api.put<Order['products']>(`/orders/${orderId}/products`, data)

  return response.data
}
