import SKUForm from './SKUForm'
import { TCreateOrUpdateSKUProps } from 'src/types'
import { useNavigate, useParams } from 'react-router-dom'
import { useDistributor } from 'src/providers/Distributor'
import { useErrorBoundary } from 'react-error-boundary'
import { ActionIcon, Box, Card, Center, Space, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconArrowNarrowLeft, IconCheck, IconX } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import { createProduct } from 'src/requests/products/createProduct'
import { updateProduct } from 'src/requests/products/updateProduct'

const CreateOrUpdateSKU = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const { distributor } = useDistributor()
  const { showBoundary } = useErrorBoundary()
  const defaultValues = distributor?.skus.find((sku) => sku['Código'] === code) as TCreateOrUpdateSKUProps | undefined

  const mutation = useMutation({
    mutationFn: code ? updateProduct : createProduct,
  })

  const handleSubmit = async (data: TCreateOrUpdateSKUProps) => {
    const notificationId = notifications.show({
      loading: true,
      title: 'Salvando seus dados...',
      message: 'Isso não deve demorar muito',
      autoClose: false,
      withCloseButton: false,
    })

    try {
      await mutation.mutateAsync(data)
      notifications.update({
        id: notificationId,
        color: 'teal',
        title: 'Dados salvos com sucesso!',
        message: 'Caso a lista não atualize, por favor, atualize manualmente',
        icon: <IconCheck size={18} />,
        loading: false,
        autoClose: 4000,
        withCloseButton: true,
      })
      navigate('/ofertas/sku')
    } catch (error) {
      notifications.update({
        id: notificationId,
        color: 'red',
        title: 'Erro ao salvar os dados.',
        message: 'Por favor, verifique os dados, caso o problema persista, entre em contato com o administrador.',
        icon: <IconX size={18} />,
        loading: false,
        withCloseButton: true,
        autoClose: 4000,
      })
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    }
  }

  return (
    <Card
      padding="xl"
      shadow="md"
      radius="md"
      style={{
        overflow: 'visible',
      }}
    >
      <Box display="flex" mb="lg">
        <Center>
          <ActionIcon
            style={{
              border: 0,
            }}
            onClick={() => navigate(-1)}
            size={24}
            color="dark"
            variant="transparent"
            p={0}
            w="fit-content"
          >
            <IconArrowNarrowLeft size={24} />
          </ActionIcon>
          <Space w="sm" />
          <Text fw={500}>{code ? `Alterar ${defaultValues?.SKU}` : 'Cadastrar SKU'}</Text>
        </Center>
      </Box>
      <SKUForm onSubmit={handleSubmit} defaultValues={defaultValues} />
    </Card>
  )
}

export default CreateOrUpdateSKU
