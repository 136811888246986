import { useEffect, useState } from 'react'
import {
  Card,
  Group,
  Box,
  Text,
  ActionIcon,
  Space,
  Divider,
  Table,
  Button,
  Select,
  InputBase,
  Flex,
  Textarea,
  Accordion,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { IconArrowNarrowLeft, IconPencil, IconTrash } from '@tabler/icons-react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import { useOrders } from 'src/providers/Orders'
import deleteDraftOrder from 'src/requests/firebase/deleteDraftOrder'
import getOrdersWithSuppliers from 'src/utils/getOrdersWithSuppliers'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import formatPrice from 'src/utils/formatPrice'
import styles from './ContinueOrder.module.css'
import sendOrderToBackend from 'src/requests/sendOrderToBackend'
import { formatUTCDate } from 'src/utils/formatDate'
import { useUpdatePrice } from '../OrdersBySKU/hooks/useUpdatePrice'
import IntlCurrencyInput from 'react-intl-currency-input'
import { IMaskInput } from 'react-imask'
import { isInfiniteStock, parseInfiniteStock } from 'src/utils/products/volume'
import { useDeleteProduct } from '../OrdersBySKU/hooks/useDeleteProduct'
import DeleteModal from '../common/Modals/DeleteModal'
import { useUpdateDesiredQty } from './hooks/useUpdateDesiredQty'
import { useSuppliers } from 'src/hooks/useSuppliers'

const INITIAL_ERROR_STATE = {
  period: '',
  date: '',
}

export default function ContinueOrder() {
  const [isSending, setIsSending] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [error, setError] = useState(INITIAL_ERROR_STATE)
  const [observations, setObservations] = useState('')
  const { showBoundary } = useErrorBoundary()
  const navigate = useNavigate()
  const { order } = useParams()
  const { distributor } = useDistributor()
  const { orders, setShouldRefetch } = useOrders()
  const { suppliers } = useSuppliers()
  const ordersWithSuppliers = getOrdersWithSuppliers(orders, distributor)
  const orderData = ordersWithSuppliers?.find((item) => item.id === order)
  const orderId = orderData?.id || '1'
  const draftOrder = orderData?.draftOrder || []
  const name = orderData?.supplier?.name || ''
  const middleman = suppliers.find((s) => s.id === orderData?.supplier?.middlemanId)
  const phone = orderData?.supplier?.phone || middleman?.phone || ''
  const isMobile = useMediaQuery('(max-width: 768px)')
  const cardPadding = isMobile ? 'md' : 'xl'
  const center = { textAlign: 'center' } as const
  const orderTotal = draftOrder.reduce((acc, item) => acc + item.price * Number(item.desiredQty), 0)
  const deliveryDate = orderData?.deliveryDate
  const [deliveryPeriod, setDeliveryPeriod] = useState(orderData?.deliveryPeriod ?? '')

  const sendMessage = async () => {
    try {
      if (!deliveryPeriod) {
        setError((old) => ({
          ...old,
          period: 'Preencha o período',
        }))
      } else {
        setIsSending(true)
        setError(INITIAL_ERROR_STATE)

        if (distributor) {
          await sendOrderToBackend({
            distributorId: distributor.distributorId,
            orderId: orderId,
            deliveryPeriod,
            observations,
          })
          setShouldRefetch(true)
          navigate('..')
          return
        }
        throw new Error('Dados para a mensagem não encontrados')
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    } finally {
      setIsSending(false)
    }
  }
  const deleteOrder = async () => {
    try {
      setIsDeleting(true)
      if (order && distributor) await deleteDraftOrder(distributor.distributorId, order)
      setShouldRefetch(true)
      navigate('..')
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    } finally {
      setIsDeleting(false)
    }
  }

  const {
    priceRef,
    handleBlurPrice,
    handleChangePrice,
    handleClickToUpdatePrice,
    handleEnterPrice,
    updatedPrice,
    isUpdatingPriceId,
    activeProductDataPrice,
  } = useUpdatePrice({
    onSave: () => setShouldRefetch(true),
  })

  const {
    activeProductDataDesiredQty,
    desiredQtyRef,
    handleBlurDesiredQty,
    handleChangeDesiredQty,
    handleClickToUpdateDesiredQty,
    handleEnterDesiredQty,
    updatedDesiredQty,
    isUpdatingDesiredQtyId,
  } = useUpdateDesiredQty({
    onSave: () => setShouldRefetch(true),
  })

  const { isDeleteModalOpen, handleCloseDeleteModal, handleDeleteProduct, handleOpenDeleteModal } = useDeleteProduct({
    onSave: () => setShouldRefetch(true),
    deleteMessage: 'SKU removido com sucesso',
  })

  useEffect(() => {
    if (draftOrder.length === 0) {
      navigate('..')
    }
  }, [draftOrder])

  return (
    <Card padding={cardPadding} shadow="md" radius="md" className={styles.card}>
      <DeleteModal
        show={isDeleteModalOpen}
        title="Remover SKU"
        description="Tem certeza que deseja remover este SKU? Essa ação é irreversível."
        onCloseModal={handleCloseDeleteModal}
        handleDelete={handleDeleteProduct}
      />
      <Group justify="space-between" px="24px">
        <Box className={styles.row}>
          <Link to="..">
            <IconArrowNarrowLeft size={24} />
          </Link>
          <Box>
            <Text fw={500}>
              {name} | {formatBrazilianNumber(phone).slice(3)}
            </Text>
            <Text size="xs" fw={600} c="main">
              {!!deliveryDate && formatUTCDate(deliveryDate, 'dd/MM/yyyy, EEEE')}
            </Text>
          </Box>
        </Box>
        <Box className={styles.headerRight}>
          <Text size="sm" fw={700}>
            Total: R$ {formatPrice(orderTotal)}
          </Text>
          <Text size="sm" c="red">
            {error.date || error.period}
          </Text>
          <Flex className={styles.formFields}>
            <Box>
              <Select
                withCheckIcon
                allowDeselect={false}
                checkIconPosition="right"
                placeholder="Periodo da entrega"
                data={['Manhã', 'Tarde', 'Madrugada', 'Qualquer']}
                value={deliveryPeriod}
                onChange={(val) => setDeliveryPeriod(val ?? '')}
                error={!!error.period}
                onClick={() =>
                  setError((old) => ({
                    ...old,
                    period: '',
                  }))
                }
                disabled={isSending || isDeleting}
              />
            </Box>
            {isMobile && (
              <Accordion
                classNames={{
                  control: styles.accordionControl,
                  content: styles.accordionContent,
                  item: styles.accordionItem,
                }}
              >
                <Accordion.Item value="Observações">
                  <Accordion.Control>Observações</Accordion.Control>
                  <Accordion.Panel>
                    <Textarea
                      value={observations}
                      onChange={(event) => setObservations(event.currentTarget.value)}
                      autosize
                      minRows={2}
                      maxRows={4}
                      maxLength={1000}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            )}
            <Button onClick={sendMessage} disabled={isDeleting} loading={isSending} loaderProps={{ type: 'dots' }}>
              Enviar pedido
            </Button>
            {isMobile ? (
              <Button
                onClick={deleteOrder}
                disabled={isSending}
                loading={isDeleting}
                loaderProps={{ type: 'dots' }}
                variant="outline"
                color="red"
              >
                Excluir pedido
              </Button>
            ) : (
              <ActionIcon
                onClick={deleteOrder}
                disabled={isSending}
                loading={isDeleting}
                loaderProps={{ type: 'dots' }}
                variant="subtle"
                color="red"
                className={styles.btn}
              >
                <IconTrash size={21} color="red" />
              </ActionIcon>
            )}
          </Flex>
        </Box>
        <Box className={styles.accordionContainer}>
          {!isMobile && (
            <Accordion
              classNames={{
                control: styles.accordionControl,
                content: styles.accordionContent,
                item: styles.accordionItem,
              }}
            >
              <Accordion.Item value="Observações">
                <Accordion.Control>Observações</Accordion.Control>
                <Accordion.Panel>
                  <Textarea
                    classNames={{ input: styles.textareaInput }}
                    value={observations}
                    onChange={(event) => setObservations(event.currentTarget.value)}
                    autosize
                    minRows={2}
                    maxRows={4}
                    maxLength={1000}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
        </Box>
      </Group>
      <Space h="md" />
      <Divider h="md" />
      {draftOrder.length === 0 ? (
        <Text className={styles.blank}>Nenhum pedido encontrado para esse fornecedor</Text>
      ) : (
        <Table.ScrollContainer minWidth={300}>
          <Table withRowBorders={false} verticalSpacing="sm">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>SKU</Table.Th>
                <Table.Th style={center}>Qtd desejada</Table.Th>
                <Table.Th style={center}>Unidade</Table.Th>
                <Table.Th style={center}>Preço</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {draftOrder.map((orderItem, index) => {
                const desiredQty = Number(orderItem.desiredQty)
                const skuMatch = distributor?.skus.find((sku) => sku['SKU'] === orderItem.sku)
                const unit = skuMatch ? skuMatch['Medida'] : ''
                const skuName = orderItem.sku
                const price = formatPrice(orderItem.price)
                const isUpdatingPrice = isUpdatingPriceId && activeProductDataPrice?.skuName === skuName
                const isUpdatingDesiredQty = isUpdatingDesiredQtyId && activeProductDataDesiredQty?.skuName === skuName
                return (
                  <Table.Tr key={index}>
                    <Table.Td>{skuName}</Table.Td>
                    <Table.Td align="center">
                      {isUpdatingDesiredQty ? (
                        <InputBase
                          inputRef={desiredQtyRef}
                          variant="filled"
                          component={IMaskInput}
                          mask={/^\d+$/}
                          defaultValue={updatedDesiredQty ?? desiredQty}
                          onChange={handleChangeDesiredQty}
                          onBlur={handleBlurDesiredQty}
                          onKeyDown={handleEnterDesiredQty}
                          w={'fit-content'}
                        />
                      ) : (
                        <Flex align="center" justify="center" w="100%">
                          {parseInfiniteStock(desiredQty)}
                          {!isInfiniteStock(desiredQty) && (
                            <ActionIcon
                              ml={4}
                              variant="subtle"
                              onClick={handleClickToUpdateDesiredQty({
                                orderId,
                                skuName,
                              })}
                            >
                              <IconPencil />
                            </ActionIcon>
                          )}
                        </Flex>
                      )}
                    </Table.Td>
                    <Table.Td align="center">{unit}</Table.Td>
                    <Table.Td align="center">
                      {isUpdatingPrice ? (
                        <InputBase
                          inputRef={priceRef}
                          component={IntlCurrencyInput}
                          variant="filled"
                          currency="BRL"
                          defaultValue={updatedPrice ?? orderItem.price}
                          value={updatedPrice ?? orderItem.price}
                          max={Number.MAX_SAFE_INTEGER}
                          onChange={handleChangePrice}
                          onBlur={handleBlurPrice}
                          onKeyPress={handleEnterPrice}
                          w={'fit-content'}
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                      ) : (
                        <Flex
                          align="center"
                          justify={'center'}
                          w="100%"
                          style={orderItem?.priceExceedsFixedPrice ? { color: 'red' } : {}}
                        >
                          {price}
                          <ActionIcon
                            ml={4}
                            variant="subtle"
                            onClick={handleClickToUpdatePrice({
                              orderId,
                              skuName,
                            })}
                          >
                            <IconPencil />
                          </ActionIcon>
                        </Flex>
                      )}
                    </Table.Td>
                    <Table.Td>
                      <ActionIcon
                        onClick={handleOpenDeleteModal({
                          orderId,
                          sku: skuName,
                        })}
                        disabled={isSending}
                        loading={isDeleting}
                        loaderProps={{ type: 'dots' }}
                        variant="subtle"
                        color="red"
                        className={styles.btn}
                      >
                        <IconTrash size={21} color="red" />
                      </ActionIcon>
                    </Table.Td>
                  </Table.Tr>
                )
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      )}
    </Card>
  )
}
