import { ActionIcon, Box, Button, Card, Center, Collapse, Flex, Space, Text, Title } from '@mantine/core'
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks'
import { IconFilter, IconPlus } from '@tabler/icons-react'
import { parseISO } from 'date-fns'
import { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useCategories } from 'src/hooks/useCategories'
import { useFilterArray } from 'src/hooks/useFilterArray'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { useSKUs } from 'src/hooks/useSKUs'
import { removeDuplicatedValuesFromArray } from 'src/utils/removeDuplicatedValuesFromArray'
import { CustomTable } from './Table'
import styles from '../Supplier.module.css'
import { useDisplayableSuppliers } from 'src/hooks/useDisplayableSuppliers'
import { useSuppliers } from 'src/hooks/useSuppliers'
import {
  makeMiddlemanCategories,
  makeMiddlemanProducts,
  makeMiddlemanReceivingDays,
  makeMiddlemanRepresentedSuppliersCodes,
  makeMiddlemanRepresentedSuppliersNames,
} from './utils/middleman-factories'
import SuppliersTableFilter from 'src/components/Supplier/SuppliersTable/Table/Filter'
import { type SuppliersTableProps } from 'src/types'

// XXX: Make component flexible so mocks can be injected when testing
export default function ProducersTable({ options }: SuppliersTableProps) {
  const { displayableSuppliers } = options?.suppliers
    ? { displayableSuppliers: options.suppliers }
    : useDisplayableSuppliers()
  const { suppliers } = options?.suppliers ? { suppliers: options.suppliers } : useSuppliers()
  const { skus, labelValueSKUs } = options?.skus ? options.skus : useSKUs()
  const categories = options?.categories ? options?.categories : useCategories()
  const [scroll] = useWindowScroll()
  const cardRef = useRef<HTMLDivElement>(null)
  const { setQueryParams, getQueryParams } = useQueryParams()

  // FILTER
  const search = getQueryParams('search')
  const selectedCategories = getQueryParams('categories').split(',').filter(Boolean)
  const selectedTags = getQueryParams('tags').split(',').filter(Boolean)
  const selectedSKUs = getQueryParams('skus').split(',').filter(Boolean)
  const deliveryDate = getQueryParams('date') ? parseISO(getQueryParams('date')) : null
  const weekDay = String(deliveryDate?.getDay() ?? '')
  const [filterOpened, { toggle: toggleFilter }] = useDisclosure(true)

  const producersWithCategories = useMemo(() => {
    return displayableSuppliers.map((supplier) => ({
      ...supplier,
      categories: removeDuplicatedValuesFromArray(makeMiddlemanCategories(supplier, suppliers, skus)),
      receivingDays: removeDuplicatedValuesFromArray(makeMiddlemanReceivingDays(supplier, suppliers)),
      skuCodesReference: removeDuplicatedValuesFromArray(makeMiddlemanProducts(supplier, suppliers)),
      representedSuppliersNames: makeMiddlemanRepresentedSuppliersNames(supplier, suppliers),
      representedSuppliersCodes: makeMiddlemanRepresentedSuppliersCodes(supplier, suppliers),
    }))
  }, [displayableSuppliers, skus, suppliers])

  const filteredProducers = useFilterArray(producersWithCategories, [
    {
      type: 'single',
      keys: ['code', 'phone', 'name', 'representedSuppliersNames', 'representedSuppliersCodes'],
      value: search,
    },
    {
      type: 'multiple',
      keys: ['categories'],
      value: selectedCategories,
    },
    {
      type: 'multiple',
      keys: ['skuCodesReference'],
      value: selectedSKUs,
      returnEmpty: true,
    },
    {
      type: 'single',
      keys: ['receivingDays'],
      value: weekDay,
    },
    {
      type: 'multiple',
      keys: ['tags'],
      value: selectedTags,
    },
  ])

  function handleOnChangeFilter(name: string, value?: string) {
    setQueryParams({
      [name]: value,
    })
  }

  const isMobile = useMediaQuery('(max-width: 575px)')

  return (
    <Card
      py={{
        base: 'md',
        md: 'xl',
      }}
      px={0}
      shadow="md"
      radius="md"
      ref={cardRef}
    >
      <Box className={styles.fixed_table_name} w={cardRef.current?.offsetWidth ?? 0} top={scroll.y > 0 ? 60 : 80}>
        <Flex justify="space-between" align="center">
          <Title order={3}>Fornecedores</Title>
          <Box hidden={scroll.y > 0}>
            <Flex align="center" gap={16}>
              <ActionIcon
                onClick={toggleFilter}
                loaderProps={{ type: 'dots' }}
                variant={filterOpened ? 'light' : 'subtle'}
                size="lg"
                title="Filtro"
              >
                <IconFilter size={20} />
              </ActionIcon>
              <Button to="/fornecedores/cadastrar" size="compact-md" component={Link}>
                {isMobile ? <IconPlus width={20} height={20} /> : 'Cadastrar fornecedor'}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box px={25} mt={30}>
        <Collapse in={filterOpened}>
          <SuppliersTableFilter
            query={{
              search,
              selectedCategories,
              selectedSKUs,
              deliveryDate,
              skus: labelValueSKUs,
              categories,
              selectedTags,
            }}
            onChange={handleOnChangeFilter}
          />
        </Collapse>
      </Box>
      <Space h="md" />
      {!displayableSuppliers.length ? (
        <Center>
          <Text>Você ainda não tem fornecedor cadastrados</Text>
        </Center>
      ) : (
        <div className="test2">
          <CustomTable items={filteredProducers} />
        </div>
      )}
    </Card>
  )
}
