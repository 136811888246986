import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import formatPrice from './formatPrice'
import type { Distributor, OrderFromBackend, Producer, ReceivedOffer } from 'src/types'
import { formatDate, formatUTCDate } from './formatDate'

import { decideOrderStatus } from './decideOrderStatus'
import { decideSupplierPhone } from './suppliers/decideSupplierPhone'

const sentOrdersHeader = [
  'Identificador',
  'Data de entrega',
  'Data do pedido',
  'Comprador',
  'Fornecedor',
  'Telefone',
  'SKU',
  'Categoria',
  'Medida',
  'Valor (por medida)',
  'Quantidade',
  'Valor total',
  'Status',
]

type OrderWithSupplier = OrderFromBackend & {
  supplier?: Producer
}

export function sanitizeSentOrdersToCsv(
  ordersWithSuppliers: OrderWithSupplier[],
  skus: Distributor['skus'] = [],
  middlemen: Producer[],
) {
  const dataBodyToExportCsv = ordersWithSuppliers.flatMap((order) => {
    const id = order.id
    const date = order.sentAt ? formatDate(order.sentAt, 'dd/MM/yyyy') : ''
    const deliveryDate = order.deliveryDate ? formatUTCDate(order.deliveryDate, 'dd/MM/yyyy') : ''
    const buyerName = order.buyer?.name ?? 'Não informado'
    const producerName = order.supplier?.name ?? ''
    const contactPhone = decideSupplierPhone(order, middlemen)
    const phone = formatBrazilianNumber(contactPhone).slice(3)
    const skusInDraftOrder = order.sentOrder.map((orderItem) => {
      const sku = orderItem.sku
      const pricePerUnit = formatPrice(orderItem.price)
      const category = skus.find((s) => s['SKU'] === sku)?.['Categoria'] ?? 'Não informado'
      const unit = skus.find((s) => s['SKU'] === sku)?.['Medida'] ?? ''
      const amount = orderItem.desiredQty
      const total = formatPrice(orderItem.price * Number(orderItem.desiredQty))
      const status = decideOrderStatus(order.isCanceled, order.confirmedByProducer)

      return [
        id,
        deliveryDate,
        date,
        buyerName,
        producerName,
        phone,
        sku,
        category,
        unit,
        pricePerUnit,
        amount,
        total,
        status,
      ]
    })
    return skusInDraftOrder
  })

  const csvData = [sentOrdersHeader, ...dataBodyToExportCsv]
  return csvData
}

const receivedOffersHeader = [
  'Identificador',
  'Data de entrega',
  'Data da oferta',
  'Solicitante',
  'Fornecedor',
  'Telefone',
  'SKU',
  'Categoria',
  'Medida',
  'Valor (por medida)',
  'Quantidade',
  'Valor total',
]

export function sanitizeReceivedOffersToCsv(offers: ReceivedOffer[]) {
  const dataBodyToExportCsv = offers.map((offer) => {
    const id = offer.id
    const date = offer.date
    const deliveryDate = offer.deliveryDate ? formatUTCDate(offer.deliveryDate, 'dd/MM/yyyy') : ''
    const requesterName = offer.requester?.name ?? 'Não informado'
    const producerName = offer.supplier.name
    const producerPhone = offer.supplier.phone
    const category = offer.category ?? 'Não informado'
    const sku = offer.sku
    const pricePerUnit = offer.price
    const volume = offer.volume.replace(/\D/g, '')
    const unit = offer.unit
    const total = formatPrice(parseFloat(offer.price.replace(',', '.')) * Number(volume))

    return [
      id,
      deliveryDate,
      date,
      requesterName,
      producerName,
      producerPhone,
      sku,
      category,
      unit,
      pricePerUnit,
      volume,
      total,
    ]
  })

  return [receivedOffersHeader, ...dataBodyToExportCsv]
}
