import { api } from 'src/services/api'
import { ChannelFromBackend } from 'src/types'

type GetChannelsFilter = {
  search?: string
  tags?: string
}

export async function getChannels(filter: GetChannelsFilter, signal?: AbortSignal) {
  const response = await api.get<ChannelFromBackend[]>('/channels', {
    params: filter,
    signal,
  })

  return response.data
}
