import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CustomMultiSelect, CustomMultiSelectOption, CustomMultiSelectProps } from './CustomMultiSelect'
import { listTags } from 'src/requests/tags/listTags'
import { useMemo, useState } from 'react'
import { createTag } from 'src/requests/tags/createTag'

type TagsMultiSelectProps = Pick<
  CustomMultiSelectProps,
  'creatable' | 'placeholder' | 'error' | 'label' | 'maxDisplayedValues'
> & {
  value?: string[]
  onChange?: (values: string[]) => void
}

export function TagsMultiSelect(props: TagsMultiSelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()

  const { data: tags, isLoading } = useQuery({
    queryKey: ['tags'],
    queryFn: listTags,
    enabled: isOpen || !!props.value?.length,
  })

  const createMutation = useMutation({
    mutationKey: ['tags'],
    mutationFn: createTag,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['tags'],
      })
    },
  })

  function onChange(items: CustomMultiSelectOption[]) {
    props.onChange?.(items.map((item) => item.value))
  }

  const labelValueTags = useMemo(() => {
    if (!tags) return []

    return tags.map((tag) => ({
      label: tag.name,
      value: tag.id,
    }))
  }, [tags])

  const value = labelValueTags.filter((tag) => {
    const selected = props.value?.includes(tag.value)

    return selected
  })

  async function onCreateOption(name: string) {
    const tag = await createMutation.mutateAsync({ name })

    return {
      value: tag.id,
      label: tag.name,
    }
  }

  return (
    <CustomMultiSelect
      options={labelValueTags}
      value={value}
      creatable={props.creatable}
      onChange={onChange}
      placeholder={props.placeholder}
      label={props.label}
      error={props.error}
      onOpenedChange={setIsOpen}
      isLoading={isLoading || createMutation.isPending}
      maxDisplayedValues={props.maxDisplayedValues}
      onCreateValue={onCreateOption}
    />
  )
}
