import { Flex, Group, Input, Loader, Text } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { TagsMultiSelect } from 'src/components/common/Inputs/TagsMultiSelect'
import CardMessageContact from 'src/components/Messages/CardMessageContact'
import PrivatePage from 'src/components/PrivatePage'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { getChannels } from 'src/requests/channels/getChannels'

export default function ListMessages() {
  const { getQueryParams, setQueryParams } = useQueryParams()

  const search = getQueryParams('search')
  const tags = getQueryParams('tags')
  const selectedTags = tags.split(',').filter(Boolean)

  const filter = {
    search,
    tags,
  }

  const { data: channels, isLoading: isLoadingChannels } = useQuery({
    queryKey: ['channels', filter],
    queryFn: ({ signal }) => getChannels(filter, signal),
    refetchInterval: import.meta.env.VITE_CHANNELS_REFETCH_INTERVAL,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  function onChangeInput(name: string, value: string) {
    setQueryParams({
      [name]: value,
    })
  }

  return (
    <PrivatePage>
      <Flex
        align="center"
        direction="column"
        gap="md"
        w="100%"
        mx="auto"
        maw={{
          base: '100%',
          sm: 600,
        }}
      >
        <Group w="100%" gap="xs">
          <Input
            w="100%"
            id="search"
            name="search"
            placeholder="Pesquisa por nome"
            leftSection={<IconSearch size={16} />}
            value={search}
            onChange={(e) => {
              onChangeInput('search', e.currentTarget.value)
            }}
          />
          <Input.Wrapper w="100%">
            <TagsMultiSelect
              placeholder="Selecione uma ou mais tags"
              maxDisplayedValues={8}
              value={selectedTags}
              onChange={(values) => {
                onChangeInput('tags', values.join(','))
              }}
            />
          </Input.Wrapper>
        </Group>
        {!isLoadingChannels &&
          !!channels?.length &&
          channels.map((channel) => (
            <CardMessageContact
              key={channel.id}
              lastMessage={channel.lastMessage}
              name={channel.producerName}
              unread={channel.unreadCount}
              phone={channel.supplier.phone}
            />
          ))}
        {isLoadingChannels && <Loader />}
        {!isLoadingChannels && !channels?.length && <Text>Nenhuma conversa encontrada</Text>}
      </Flex>
    </PrivatePage>
  )
}
