import { Table } from '@mantine/core'

export function CustomTableHead() {
  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Data do pedido</Table.Th>
        <Table.Th>Data de entrega</Table.Th>
        <Table.Th>Comprador</Table.Th>
        <Table.Th>Fornecedor</Table.Th>
        <Table.Th>SKU</Table.Th>
        <Table.Th>Valor</Table.Th>
        <Table.Th>Resumo</Table.Th>
        <Table.Th ta="center">Status</Table.Th>
        <Table.Th></Table.Th>
      </Table.Tr>
    </Table.Thead>
  )
}
