import { MESSAGE_STATUS } from 'src/types'
import { BalloonProps } from './Chat'
import { IconCheck, IconChecks, IconClock } from '@tabler/icons-react'
import { FailedStatus } from './FailedStatus'

export const StatusIcons: Record<MESSAGE_STATUS, React.FC<Pick<BalloonProps, 'resendFailedMessage'>>> = {
  queued() {
    return <IconClock color="#8696a0" size={14} />
  },
  sent() {
    return <IconCheck color="#8696a0" size={16} />
  },
  delivered() {
    return <IconChecks color="#8696a0" size={16} />
  },
  read() {
    return <IconChecks color="#53bdeb" size={16} />
  },
  sending() {
    return <IconClock color="#8696a0" size={14} />
  },
  canceled: FailedStatus,
  failed: FailedStatus,
  undelivered: FailedStatus,
}

export function getStatusIcon(status?: MESSAGE_STATUS | null) {
  if (!status) {
    return null
  }

  const component = StatusIcons[status]

  return component
}
