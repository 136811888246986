import { MultiSelect as BaseMultiSelect, type MultiSelectProps } from '@mantine/core'
import { ReactNode } from 'react'
import { useQueryParams } from 'src/hooks/useQueryParams'

interface Props extends MultiSelectProps {
  children?: ReactNode
  options?: { filter?: string }
  onChange: (values: string[]) => void
}

export default function MultiSelect({ onChange, name, options, children, ...props }: Props) {
  const { setQueryParams } = useQueryParams()
  const key = options?.filter ? options?.filter : name ? name : ''

  return (
    <BaseMultiSelect
      {...props}
      onChange={(values) => {
        setQueryParams({ [key]: values.join(',') })
        onChange(values)
      }}
    >
      {children}
    </BaseMultiSelect>
  )
}
