import { api } from 'src/services/api'
import type { OrderFromBackend, Pagination, PaginationReturn } from 'src/types'

interface Params extends Pagination {
  distributorId: string
  createdAt: Date[]
  suppliers: string[]
}

export async function getAllOffers(params: Params) {
  const response = await api.get<PaginationReturn<OrderFromBackend[]>>('/offers', {
    params,
  })

  return response.data
}
