import { updateProducts } from 'src/requests/offer/products/updateProducts'

type UpdateOrderProductsVolumeContext = {
  distributorId: string
  orderId: string
  newVolume: number
  sku: string
}

export default async function updateOrderProductsVolume({ orderId, newVolume, sku }: UpdateOrderProductsVolumeContext) {
  return await updateProducts(
    { orderId },
    {
      products: [
        {
          sku,
          volume: newVolume,
        },
      ],
    },
  )
}
