import { useState, useMemo } from 'react'
import {
  Card,
  Space,
  Table,
  Button,
  ActionIcon,
  InputBase,
  Flex,
  Group,
  Stack,
  MultiSelect,
  Tooltip,
  Box,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { IconArrowNarrowLeft, IconHourglassLow, IconPencil } from '@tabler/icons-react'
import { useErrorBoundary } from 'react-error-boundary'
import { useDistributor } from 'src/providers/Distributor'
import { useOrders } from 'src/providers/Orders'
import createDraftOrder from 'src/requests/firebase/createDraftOrder'
import getOrdersWithSuppliers from 'src/utils/getOrdersWithSuppliers'
import prepareOrdersTable from 'src/utils/prepareOrdersTable'
import formatBrazilianNumber from 'src/utils/formatBrazilianNumber'
import formatPrice from 'src/utils/formatPrice'
import type { QtyState } from 'src/types'
import styles from './OrdersBySKU.module.css'
import IntlCurrencyInput from 'react-intl-currency-input'
import { useUpdateVolume } from './hooks/useUpdateVolume'
import { IMaskInput } from 'react-imask'
import { useUpdatePrice } from './hooks/useUpdatePrice'
import { useDeleteProduct } from './hooks/useDeleteProduct'
import DeleteModal from '../common/Modals/DeleteModal'
import { isInfiniteStock, parseInfiniteStock } from 'src/utils/products/volume'
import { useFilterArray } from 'src/hooks/useFilterArray'
import { useQueryParams } from 'src/hooks/useQueryParams'
import { DatePickerInput } from '@mantine/dates'
import { formatUTCDateAndWeekDay } from 'src/utils/dates/formatDateAndWeekDay'
import { useDeliveryDate } from 'src/hooks/useDeliveryDate'
import { useSuppliers } from 'src/hooks/useSuppliers'
import { formatPercentage } from 'src/utils/intl/formatPercentage'
import { DeliveryDate } from '../common/Inputs/DeliveryDate'
import { formatUTCDate } from 'src/utils/formatDate'
import { UTCDate } from '@date-fns/utc'
import classes from 'src/components/Table/Table.module.css'
import OfferBySKURowAction from './components/OfferBySKURowAction'
import TableCellPopover from 'src/components/TableCellPopover'
import Text from 'src/components/Text'
import { decideSupplierPhone } from 'src/utils/suppliers/decideSupplierPhone'
import { useMiddlemen } from 'src/hooks/useMiddlemen'

export type SaveQtyParams = {
  id: string
  price: number
  volume: number
  priceExceedsFixedPrice?: boolean
  deliveryDate: Date
}

export default function OrdersBySKU() {
  const [success, setSuccess] = useState('')
  const { showBoundary } = useErrorBoundary()
  const { sku } = useParams()
  const { distributor } = useDistributor()
  const { duplicatedOrdersWithExpirationDate: orders, setShouldRefetch } = useOrders()
  const navigate = useNavigate()
  const ordersWithSuppliers = getOrdersWithSuppliers(orders, distributor)
  const skus = distributor?.skus
  const skuInfo = skus?.find((item) => item['Código'] === sku)
  const skuCode = skuInfo?.['Código']
  const skuName = (skuInfo?.SKU as string) || ''
  const skuUnit = skuInfo?.['Medida'] || ''
  const ordersWithSku = prepareOrdersTable(ordersWithSuppliers, skuName)

  const { getQueryParams, setQueryParams } = useQueryParams()

  const isoDeliveryDate = getQueryParams('date')
  const deliveryDate = useDeliveryDate(isoDeliveryDate)

  const { labelValueSuppliersWithoutMiddlemen } = useSuppliers()
  const { middlemen } = useMiddlemen()

  const selectedSuppliers = getQueryParams('suppliers').split(',').filter(Boolean)
  const filteredOrders = useFilterArray(ordersWithSku, [
    {
      type: 'single-date',
      keys: ['deliveryDate'],
      value: deliveryDate.utcDate,
    },
    {
      type: 'multiple',
      keys: ['supplierId'],
      value: selectedSuppliers,
    },
  ])

  const initialValues = filteredOrders.reduce(
    (accumulator, order) => {
      const compoundId = order.id + new Date(order.deliveryDate).toISOString()

      accumulator[compoundId] = order.draftOrder?.find((item) => item.sku === skuName)?.desiredQty || ''
      return accumulator
    },
    {} as Record<string, string>,
  )
  const [qtyState, setQtyState] = useState<QtyState>({ values: initialValues, errors: {}, loading: {} })
  const isMobile = useMediaQuery('(max-width: 768px)')
  const cardPadding = isMobile ? 'md' : 'xl'
  const header = skuCode ? (skuName ? `${skuCode} - ${skuName}` : skuCode) : skuName

  // volume
  const {
    volumeRef,
    handleClickToUpdateVolume,
    isUpdatingVolumeId,
    handleChangeVolume,
    handleEnterVolume,
    handleBlurVolume,
  } = useUpdateVolume({
    onSave: () => setShouldRefetch(true),
  })

  const {
    priceRef,
    handleBlurPrice,
    handleChangePrice,
    handleClickToUpdatePrice,
    handleEnterPrice,
    isUpdatingPriceId,
    updatedPrice,
  } = useUpdatePrice({
    onSave: () => setShouldRefetch(true),
  })

  const { isDeleteModalOpen, handleCloseDeleteModal, handleDeleteProduct } = useDeleteProduct({
    onSave: () => setShouldRefetch(true),
  })

  const totalVolume = useMemo(() => {
    return (
      filteredOrders.reduce((acc, order) => {
        const product = order.products.find((product) => product.sku === skuName)

        return acc + Number(product?.volume ?? 0)
      }, 0) ?? 0
    )
  }, [filteredOrders, skuName])

  const totalDemand = useMemo(() => {
    return distributor?.demands?.find((demand) => demand.code === skuCode || demand.sku === skuName)?.value ?? 0
  }, [distributor?.demands, skuCode, skuName])

  const isLowOffer = useMemo(() => {
    return totalDemand > totalVolume
  }, [totalDemand, totalVolume])

  const saveQty = async ({ id, price, volume, priceExceedsFixedPrice, deliveryDate }: SaveQtyParams) => {
    const compoundId = id + new Date(deliveryDate).toISOString()
    try {
      setQtyState((prevState) => ({
        ...prevState,
        loading: { ...prevState.loading, [compoundId]: true },
      }))
      if (!qtyState.errors[compoundId] && distributor) {
        const draftOrder = {
          sku: skuName,
          price,
          volume,
          desiredQty: qtyState.values[compoundId] || '0',
          priceExceedsFixedPrice: priceExceedsFixedPrice ?? false,
        }

        await createDraftOrder(distributor?.distributorId, id, draftOrder, deliveryDate)
        setShouldRefetch(true)
        setSuccess(compoundId)
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
        showBoundary(error)
      } else {
        console.log(error)
        showBoundary(error)
      }
    } finally {
      setQtyState((prevState) => ({
        ...prevState,
        loading: { ...prevState.loading, [compoundId]: false },
      }))
    }
  }

  return (
    <Card padding={cardPadding} shadow="md" radius="md" className={styles.card}>
      <DeleteModal
        show={isDeleteModalOpen}
        title="Remover oferta"
        description="Tem certeza que deseja remover esta oferta? Essa ação é irreversível."
        onCloseModal={handleCloseDeleteModal}
        handleDelete={handleDeleteProduct}
      />
      <Group gap="xs" px="lg" justify="space-between">
        <Group gap="xs">
          <ActionIcon size="md" variant="transparent" color="dark" onClick={() => navigate(-1)}>
            <IconArrowNarrowLeft size={24} />
          </ActionIcon>
          <Text fw={500}>{header}</Text>
        </Group>

        <Flex
          gap="sm"
          w="100%"
          wrap={{
            base: 'wrap',
            xs: 'nowrap',
          }}
        >
          <DatePickerInput
            label="Data de entrega"
            placeholder="Selecione uma data"
            locale="pt-br"
            valueFormat="DD/MMM"
            clearable
            w="100%"
            value={deliveryDate.zonedDate}
            onChange={(date) => {
              setQueryParams({
                date: date?.toISOString(),
              })
            }}
          />

          <MultiSelect
            w="100%"
            name="suppliers"
            placeholder="Escolha um ou mais fornecedores"
            label="Fornecedores"
            data={labelValueSuppliersWithoutMiddlemen}
            searchable
            clearable
            checkIconPosition="right"
            value={selectedSuppliers}
            onChange={(values) => {
              setQueryParams({
                suppliers: values.join(','),
              })
            }}
          />
        </Flex>
      </Group>

      <Space h="md" />
      {ordersWithSku.length === 0 ? (
        <Text className={styles.blank}>Nenhuma oferta encontrada para esse SKU</Text>
      ) : (
        <Table.ScrollContainer minWidth={300}>
          <Table withRowBorders={false} verticalSpacing="sm" className={classes.table}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Entrega</Table.Th>
                <Table.Th>Fornecedor</Table.Th>
                <Table.Th ta="center">
                  <Box>Preço</Box>
                  <Box>última compra</Box>
                </Table.Th>
                <Table.Th ta="center">Preço</Table.Th>
                <Table.Th ta="center">Volume ({skuUnit})</Table.Th>
                <Table.Th ta="center">Variação</Table.Th>
                <Table.Th ta="center">Economia</Table.Th>
                <Table.Th>Qtd desejada</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {filteredOrders?.map((order) => {
                const product = order.products.find((product) => product.sku === skuName)

                if (!product || !order.supplier) return null
                const id = order.id + new Date(order.deliveryDate).toISOString()
                const deliveryDate = formatUTCDateAndWeekDay(order.deliveryDate)
                const name = order.supplier.name
                const price = formatPrice(product.price)
                const volume = product.volume
                const isUpdatingVolume = isUpdatingVolumeId === id
                const isUpdatingPrice = isUpdatingPriceId === id
                const lastBuyoutPrice = skuInfo?.suppliersLastBuyoutPrice?.[order.supplier.code]?.price ?? 0
                const variation = product.price / lastBuyoutPrice - 1
                const savings = lastBuyoutPrice
                  ? Number(qtyState.values[id] ?? 0) * (lastBuyoutPrice - product.price)
                  : null

                const contactPhone = decideSupplierPhone(order, middlemen)
                const phone = formatBrazilianNumber(contactPhone).slice(3)

                return (
                  <Table.Tr key={id}>
                    <Table.Td>
                      <Stack gap={0} align="center" w={'fit-content'}>
                        <Tooltip
                          disabled={!order.validUntil}
                          withArrow
                          label={
                            order.validUntil
                              ? `Esta é uma oferta válida até ${formatUTCDate(order.validUntil, 'dd/MM/yyyy')}`
                              : ''
                          }
                        >
                          <div>
                            <DeliveryDate
                              setShouldRefetchOrders={setShouldRefetch}
                              orderId={order.id}
                              deliveryDate={new UTCDate(order.deliveryDate)}
                              valueFormat="DD/MM/YYYY"
                              weekDay={deliveryDate.weekDay}
                              sku={product.sku}
                              disableEdit={!!order.validUntil}
                              rightSideIcon={!!order.validUntil && <IconHourglassLow color="#ccc" />}
                            />
                          </div>
                        </Tooltip>
                      </Stack>
                    </Table.Td>
                    <Table.Td>
                      <Button
                        p="0"
                        component={Link}
                        to={`/mensagens/${contactPhone}`}
                        variant="transparent"
                        justify="start"
                        className={classes.tableCell}
                      >
                        <TableCellPopover dropdown={<Text size="sm">{name}</Text>}>
                          <Box className={classes.tableCell}>
                            <Text truncate="end" inherit ta="start">
                              {name}
                            </Text>
                            <Text truncate="end" inherit ta="start" c="black" fw="normal">
                              {phone}
                            </Text>
                          </Box>
                        </TableCellPopover>
                      </Button>
                    </Table.Td>
                    <Table.Td align="center">{lastBuyoutPrice ? formatPrice(lastBuyoutPrice) : '-'}</Table.Td>
                    <Table.Td align="center">
                      {isUpdatingPrice ? (
                        <InputBase
                          inputRef={priceRef}
                          component={IntlCurrencyInput}
                          variant="filled"
                          currency="BRL"
                          defaultValue={updatedPrice ?? product.price}
                          value={updatedPrice ?? product.price}
                          max={Number.MAX_SAFE_INTEGER}
                          onChange={handleChangePrice}
                          onBlur={handleBlurPrice}
                          onKeyPress={handleEnterPrice}
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                      ) : (
                        <Flex
                          align="center"
                          justify="center"
                          w="100%"
                          style={product?.priceExceedsFixedPrice ? { color: 'red' } : {}}
                        >
                          {price}
                          <ActionIcon
                            ml={4}
                            variant="subtle"
                            onClick={handleClickToUpdatePrice({
                              orderId: order.id,
                              skuName,
                              deliveryDate: new Date(order.deliveryDate).toISOString(),
                            })}
                          >
                            <IconPencil />
                          </ActionIcon>
                        </Flex>
                      )}
                    </Table.Td>
                    <Table.Td align="center">
                      {isUpdatingVolume ? (
                        <InputBase
                          inputRef={volumeRef}
                          variant="filled"
                          component={IMaskInput}
                          mask={/^\d+$/}
                          defaultValue={volume}
                          onChange={handleChangeVolume}
                          onBlur={handleBlurVolume}
                          onKeyDown={handleEnterVolume}
                        />
                      ) : (
                        <Flex align="center" justify="center" w="100%">
                          {parseInfiniteStock(volume)}
                          {!isInfiniteStock(volume) && (
                            <ActionIcon
                              ml={4}
                              variant="subtle"
                              onClick={handleClickToUpdateVolume({
                                orderId: order.id,
                                skuName,
                                deliveryDate: new Date(order.deliveryDate).toISOString(),
                              })}
                            >
                              <IconPencil />
                            </ActionIcon>
                          )}
                        </Flex>
                      )}
                    </Table.Td>
                    <Table.Td
                      align="center"
                      c={variation && Number.isFinite(variation) ? colorConditional(variation <= 0) : undefined}
                    >
                      {variation && Number.isFinite(variation) ? formatPercentage(variation) : '-'}
                    </Table.Td>
                    <Table.Td align="center" c={savings ? colorConditional(savings >= 0) : undefined}>
                      {savings ? formatPrice(savings) : '-'}
                    </Table.Td>
                    <OfferBySKURowAction
                      sku={skuInfo}
                      order={order}
                      product={product}
                      state={qtyState}
                      success={success}
                      saveQty={saveQty}
                      setQtyState={setQtyState}
                    />
                  </Table.Tr>
                )
              })}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      )}
      <Flex align="center" justify="center" direction="column">
        <Text>
          Demanda total:
          <Text component="span" ml={6} c={isLowOffer ? 'red' : 'main'}>
            {totalDemand}
          </Text>
        </Text>
        <Text>
          Volume total:
          <Text component="span" ml={6}>
            {parseInfiniteStock(totalVolume)}
          </Text>
        </Text>
      </Flex>
    </Card>
  )
}

function colorConditional(cond: boolean) {
  return cond ? 'main' : 'red'
}
