import { Flex, Pagination, Table } from '@mantine/core'
import { CustomTableBody, SentOrdersTableBodyProps } from './Body'
import { CustomTableHead } from './Head'
import classes from 'src/components/Table/Table.module.css'

type CustomTableProps = SentOrdersTableBodyProps & {
  totalPages: number
  activePage: number
  onChangePage: (num: number) => void
}

export function CustomTable(props: CustomTableProps) {
  return (
    <>
      <Table.ScrollContainer minWidth={300}>
        <Table withRowBorders={false} verticalSpacing="sm" className={classes.table}>
          <CustomTableHead />
          <CustomTableBody items={props.items} />
        </Table>
      </Table.ScrollContainer>
      <Flex px={25} justify="center" mt="xs">
        <Pagination total={props.totalPages} value={props.activePage} onChange={props.onChangePage} />
      </Flex>
    </>
  )
}
