import { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import InitialLoader from 'src/components/InitialLoader'
import RegisterOrganization from 'src/components/RegisterOrganization'
import AppShell from 'src/components/AppShell'
import Producers from 'src/pages/Producers'
import SKUSupply from 'src/pages/SKUSupply'
import DraftOrders from 'src/pages/DraftOrders'
import SentOrders from 'src/pages/SentOrders'
import CreateTeam from 'src/components/CreateTeam'
import NotFound from 'src/pages/NotFound'
import Messages from 'src/pages/Messages'
import { useFirebaseAuth } from 'src/providers/FirebaseAuth'
import { useOrders } from 'src/providers/Orders'
import Smartlook from 'smartlook-client'
import OffersPagesRoute from './pages/Offers'
import MarketReportsPage from './pages/MarketReports'
import OneSignal from 'react-onesignal'
import { useDistributor } from './providers/Distributor'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import ListMessages from './pages/Messages/ListMessages'

if (import.meta.env.VITE_SMARTLOOK_KEY && import.meta.env.VITE_SMARTLOOK_REGION) {
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_KEY as string, {
    region: import.meta.env.VITE_SMARTLOOK_REGION,
  })
}

export default function App() {
  const { isAuthenticating } = useFirebaseAuth()
  const { user } = useKindeAuth()
  const { isLoading: isLoadingOrders } = useOrders()
  const isFetching = isAuthenticating || isLoadingOrders
  const { distributor } = useDistributor()
  const [isOneSignalInitialized, setOneSignalInitialized] = useState(false)

  function runOneSignal() {
    if (isOneSignalInitialized) return

    OneSignal.init({ appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID })
      .then(() => {
        setOneSignalInitialized(true)
        OneSignal.Slidedown.promptPush()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    runOneSignal()
    return () => {
      setOneSignalInitialized(false)
    }
  }, [])

  useEffect(() => {
    if (user?.id && OneSignal) {
      OneSignal.login(user.id)
    }
  }, [isOneSignalInitialized, user?.id])

  useEffect(() => {
    if (distributor?.distributorId && OneSignal) {
      const tags: Record<string, string> = {}
      tags['distributor_id'] = distributor.distributorId
      OneSignal.User.addTags(tags)
    }
  }, [isOneSignalInitialized, distributor?.distributorId])

  function RedirectWithPath({ from, to }: { from: string; to: string }) {
    const location = useLocation()
    const newPath = location.pathname.replace(from, to)
    return <Navigate to={newPath} replace />
  }

  if (isFetching) return <InitialLoader />
  return (
    <Routes>
      <Route path="/register-org" element={<RegisterOrganization />} />
      <Route path="/mensagens/*" element={<Messages />} />
      <Route element={<AppShell />}>
        <Route index element={<Producers />} />
        <Route path="produtores/*" element={<RedirectWithPath from="/produtores" to="/fornecedores" />} />
        <Route path="fornecedores/*" element={<Producers />} />
        <Route path="ofertas/*" element={<OffersPagesRoute />} />
        <Route path="ofertas/sku/*" element={<SKUSupply />} />
        <Route path="pedidos-abertos/*" element={<DraftOrders />} />
        <Route path="pedidos-enviados/*" element={<SentOrders />} />
        <Route path="create-team" element={<CreateTeam />} />
        <Route path="relatorios" element={<MarketReportsPage />} />
        <Route path="/mensagens" element={<ListMessages />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
