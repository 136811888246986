import { DEFAULT_PRODUCER_RECEIVING_DAYS } from 'src/constants/producers'
import type { Order, Distributor, ProducerReceivingDays } from 'src/types'
import { removeDuplicatedStrings } from './arrays/removeDuplicated'

const getDateAsString = (order: Order, key: keyof Order) => {
  const date = order[key]

  if (date === null || date === undefined) {
    return ''
  }

  if (typeof date === 'string') {
    return date
  }

  if (typeof date === 'object') {
    return date instanceof Date ? date.toISOString() : ''
  }

  return ''
}

export default function getOrdersWithSuppliers<T extends Order>(
  orders: T[] | null = [],
  distributor: Distributor | null,
  orderBy?: { key: keyof Order; sort: 'asc' | 'desc' },
) {
  const parsedOrders =
    orders?.map((order) => {
      const supplier = distributor?.producers?.find((s) => s.id === order.supplierId)

      const receivingDays: ProducerReceivingDays[] = []

      const supplierReceivingDays = supplier?.receivingDays ?? DEFAULT_PRODUCER_RECEIVING_DAYS

      if (supplier) {
        receivingDays.push(...supplierReceivingDays)
      }

      const receivingDaysWithoutDuplicated = removeDuplicatedStrings(receivingDays)

      return {
        ...order,
        supplier,
        receivingDays: receivingDaysWithoutDuplicated,
      }
    }) || []

  // TODO: order.deliveryDate has typing inconsistencies, it's either stored as a `Date` object or as `string`
  // NOTE: Sort recent orders by delivery date first
  if (!orderBy) {
    parsedOrders.sort((order, nextOrder) => {
      const deliveryDate = getDateAsString(order, 'deliveryDate')
      const nextDeliveryDate = getDateAsString(nextOrder, 'deliveryDate')

      return deliveryDate.localeCompare(nextDeliveryDate)
    })
  } else {
    parsedOrders.sort((order, nextOrder) => {
      const date = getDateAsString(order, orderBy.key)
      const nextDate = getDateAsString(nextOrder, orderBy.key)

      return orderBy.sort === 'desc' ? -date.localeCompare(nextDate) : date.localeCompare(nextDate)
    })
  }

  return parsedOrders
}
