import type { Dispatch, SetStateAction, ChangeEventHandler, ReactNode } from 'react'
import type { Timestamp } from 'firebase/firestore'
import { FileExtension } from './components/Chat/Chat'

//------------------------------------------------- General
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never

export type Obj<T> = Record<string, T>

export type SetState<T> = Dispatch<SetStateAction<T>>

export type OnChange = ChangeEventHandler<HTMLInputElement>

// export type Only<I, J> = {
//   [P in keyof I]: P extends J ? never : I[P]
// }

export type Only<I, J> = {
  [P in keyof I]: I[P]
} & {
  [P in keyof J]: never
}

export type OneOrAnother<I, J> = Only<I, J> | Only<J, I>

//------------------------------------------------- Custom http error

export type CustomError = {
  customError: {
    message: string
    reason: string
    extraDetails: unknown
  }
}

//------------------------------------------------- Providers props

export type RouterProps = {
  children: ReactNode
}

export type KindeProps = {
  children: ReactNode
}

export type MantineProps = {
  children: ReactNode
}

export type FirebaseAuthProps = {
  children: ReactNode
}

export type FirebaseAuthContextValue = {
  isAuthenticating: boolean
  setPreventAuth: SetState<boolean>
}

export type OrdersProps = {
  children: ReactNode
}

export type OrdersContextValue = {
  isFetching: boolean
  isLoading: boolean
  setShouldRefetch: (bool: boolean) => void
  orders: OrderWithMetrics[] | null
  duplicatedOrdersWithExpirationDate: OrderWithMetrics[]
  onCancelOrder: (orderId: string) => Promise<void>
}

export type DistributorProps = {
  children: ReactNode
}

export type DistributorContextValue = {
  isFetching: boolean
  setDistributor: SetState<Distributor | null>
  distributor: Distributor | null
}

export type AxiosProps = {
  children: ReactNode
}

export type KindeUser = {
  id: string | null
  given_name: string | null
  family_name: string | null
  email: string | null
  picture: string | null
}

//------------------------------------------------- Components props

export type ErrorBoundaryProps = {
  children: ReactNode
}

export type ErrorProps = {
  error: unknown
  resetErrorBoundary: () => void
}

export type PrivatePageProps = {
  children: ReactNode
}

export type HeaderProps = {
  navbarIsOpen: boolean
  openNavbar: () => void
}

export type NavbarProps = {
  navbarIsOpen: boolean
  closeNavbar: () => void
  useDrawer?: boolean
}

export type NavbarContentProps = {
  pathname: string
  isMobile: boolean
}

export type UserMenuProps = {
  picture: string
  initials: string
  email: string
  logout: () => void
}

export type UserAvatarProps = {
  picture: string
  initials: string
  notClickable?: boolean
}

export type ModalStandardProps = {
  opened: boolean
  onClose: () => void
  title: string
  children: ReactNode
}

//------------------------------------------------- Requests

export type ProducerReceivingDays = '0' | '1' | '2' | '3' | '4' | '5' | '6'

export type ProducerAddress = {
  zipCode?: string
  state?: string | null
  city?: string
  neighborhood?: string
  street?: string
  number?: string
  complement?: string
}

export type Producer = {
  id: string
  type: SUPPLIER_TYPE
  lastRequest?: Timestamp | undefined
  lastOffer?: Timestamp | undefined
  phone: string
  code: string
  name: string
  rejectionRate?: number
  taxpayerIdentificationNumber?: string
  address: ProducerAddress
  observations?: string
  hasInfiniteStock?: boolean
  skuCodesReference?: string[]
  receivingDays?: ProducerReceivingDays[]
  minimumOrderPrice?: number
  offersValidForDays?: number
  middlemanId?: string | null
  representedSuppliers?: string[]
  tags?: string[]
}

export type SKU = {
  Código: string
  Medida: string
  Categoria: string
  SKU: string
  lastBuyoutPrice?: number
  suppliersLastBuyoutPrice?: {
    [supplierCode: string]: {
      price: number
      date: Date
    }
  }
  tags?: string[]
}

export type Distributor = {
  distributorId: string
  fixedPrices: {
    fixedPrice: number
    producerCode: string
    producerName: string
    sku: string
  }[]
  information: {
    logo: string
    name: string
  }
  members: {
    [userId: string]: {
      name: string
      role: string
    }
  }
  skus: SKU[]
  producers: Producer[]
  demands?: Demand[]
}

export type Distributors = Distributor[] | null

export type SKUsMercadoDiferente = {
  Código: string
  SKU: string
  Medida: string
  Categoria: string
}

export type DistributorMercadoDiferente = Expand<
  Omit<Distributor, 'skus'> & {
    skus: SKUsMercadoDiferente[]
  }
>

export type Order = {
  createdAt: Timestamp | null
  draftOrder: DraftOrder[]
  id: string
  supplierId: string
  phone: string
  validUntil?: Date
  invoiceUrl?: string
  products: {
    price: number
    sku: string
    category?: string
    unit: string
    volume: number
    priceExceedsFixedPrice?: boolean
    priceHistory?: [
      {
        oldPrice: number
        newPrice: number
        changedBy: string
        changedAt: Timestamp
      },
    ]
    metrics?: ProductMetrics
  }[]
  sentAt: Timestamp | null
  sentOrder: SentOrder[]
  isCanceled?: boolean
  expired?: boolean
  deliveryDate: Date
  deliveryPeriod?: string
  buyer?: {
    id: string
    name: string
  }
  requester?: {
    id: string
    name: string
  }
}

export type OrderPriceHistory = {
  oldPrice: number
  newPrice: number
  changedBy: string
  changedAt: Timestamp
}

type ProductMetrics = {
  lastBuyoutPrice: number
  variation: number
  pastDaysAverageBuyoutPrice: number
}

export type OrderWithMetrics = Order & {
  products: Order['products'][number] & ProductMetrics
}

export type OrderWithSupplier = Order & {
  supplier?: Producer
}

export type Orders = Order[] | null

export type OrderImport = {
  producerCode: string
  deliveryDate: string
  deliveryPeriod: DeliveryPeriod
  sku: string
  desiredQty: number
  price: number
}

export type QtyState = {
  values: { [key: string]: string }
  errors: { [key: string]: string }
  loading: { [key: string]: boolean }
}

export type DraftOrder = {
  desiredQty: string
  price: number
  sku: string
  volume: number
  priceExceedsFixedPrice?: boolean
}

export type SentOrder = {
  desiredQty: string
  price: number
  category?: string
  sku: string
  volume: number
}

export type WhatsMessage = {
  userNumber: string
  sid?: string
  text?: string
  variables?: string
  resend?: boolean
  messageSid?: string
  isManual?: boolean
}

export enum DeliveryPeriod {
  MANHA = 'Manhã',
  TARDE = 'Tarde',
  MADRUGADA = 'Madrugada',
  QUALQUER = 'Qualquer',
}

export enum MESSAGE_TYPE {
  TEXT_DEFAULT = 'text-default',
  TEXT = 'text',
  AUDIO = 'audio',
  VIDEO = 'video',
  IMAGE = 'image',
  APPLICATION = 'application',
  INTERACTIVE = 'interactive',
}

export enum MESSAGE_STATUS {
  QUEUED = 'queued',
  SENT = 'sent',
  DELIVERED = 'delivered',
  READ = 'read',
  UNDELIVERED = 'undelivered',
  CANCELED = 'canceled',
  SENDING = 'sending',
  FAILED = 'failed',
}

export type Message = {
  id: string
  fromOrg: boolean
  content?: string | null
  mediaUrl?: string | null
  type: MESSAGE_TYPE
  createdAt: Timestamp
  status?: MESSAGE_STATUS | null
  isManual?: boolean
  mediaName?: string
  fileExtension?: FileExtension
}

export type Channel = {
  supplier?: Producer
  createdAt: Date
  updatedAt: Date
  chatlogs: Message[]
}

export type ChannelFromBackend = {
  id: string
  createdAt: Date
  updatedAt: Date
  producerName: string
  lastOrderSentDate?: Date
  unreadCount?: number
  lastMessage?: MessageFromBackend
  supplier: Pick<Producer, 'id' | 'phone' | 'name'>
}

export type MessageFromBackend = {
  id: string
  fromOrg: boolean
  content?: string | null
  mediaUrl?: string | null
  type: MESSAGE_TYPE
  createdAt: Date
  status?: MESSAGE_STATUS | null
  isManual?: boolean
}

export type TCreateUpdateOrDeleteSKU = {
  Código: string
  Medida: string
  Categoria: string
  SKU: string
  tags?: string[]
}

export type TCreateOrUpdateSKUProps = Omit<TCreateUpdateOrDeleteSKU, 'distributorId'>

export type TDeleteProducer = Producer & {
  distributorId: string
}

export type TCreateUpdateProducer = Omit<Producer, 'id'> & {
  distributorId: string
  fixedPrices: TCreateFixedPrices[]
  id?: string
}

export type TCreateOrUpdateSupplierProps = Omit<Producer, 'id'>

export type TCreateFixedPrices = {
  producerName: string
  producerCode: string
  sku: string
  fixedPrice: number
}

export type TCreateOrUpdateSupplierForm = TCreateOrUpdateSupplierProps & {
  fixedPrices: Array<
    Pick<TCreateFixedPrices, 'sku'> & {
      fixedPrice: number
    }
  >
}

export type Demand = {
  code: string
  sku: string
  value: number
}

export type TCreateOrUpdateDemand = {
  distributorId: string
  demands: Demand[]
}

export type TCreateOffer = {
  supplierId: string
  products: Order['products']
  deliveryDate: Date
}

export type GreatPurchaseProducer = {
  name: string | undefined
  phone: string
  rejectionRate: number
}

export type GreatPurchaseOffer = {
  producer: GreatPurchaseProducer
  orderId: string
  sku: string
  price: number
  volume: number
  unit: string
  desiredQty: number
}

export type GreatPurchaseProduct = {
  name: string
  demand: number
  offers: GreatPurchaseOffer[]
}

export type GreatPurchase = {
  id: string
  createdAt: Date
  updatedAt: Date
  products: GreatPurchaseProduct[]
  status: SolverStatus
  totalPrice: number
}

// TODO: Should extend base type `Order`
export type ReceivedOffer = {
  id: string
  supplierId?: string
  sku: string
  createdAt?: Date | null
  category?: string
  supplier: {
    phone: string
    name: string
    contactPhone?: string
  }
  deliveryDate: Date
  volume: string
  price: string
  priceExceedsFixedPrice?: boolean
  date: string
  unit: string
  sentAt?: Date | null
  validUntil?: Date
  requester?: {
    id: string
    name: string
  }
}

export type Notification = {
  id: string
  isRead: boolean
  producerName: string
  producerPhone: string
  notificationType: string
  createdAt: Date
}

export type PostmonResponse = {
  bairro: string
  cidade: string
  logradouro: string
  cep: string
  estado: string
}

export interface Pagination {
  page: number
  size?: number
}

export type PaginationReturn<T extends Record<string, any>[]> = {
  records: T
  page: number
  last: number
  size: number
}

export type OrderFromBackend = Order & {
  createdAt: Date
  deliveryDate: Date
  sentAt: Date | null
  confirmedByProducer: boolean
}

export enum SolverStatus {
  OPTIMAL = 'OPTIMAL', // Mostra normal
  FEASIBLE = 'FEASIBLE', // Mostra com alerta
  INFEASIBLE = 'INFEASIBLE', // Dadas as ofertas e demanda informada, não foi possível encontrar uma solução.
  NOFEASIBLE = 'NOFEASIBLE', // Dadas as ofertas e demanda informada, não há uma solução que satisfaça as restrições.'
  UNBNDOUNDED = 'UNBNDOUNDED', // Esse problema não tem uma solução finita ótima.
  UNDEFINED = 'UNDEFINED', // Dados insuficientes para encontrar uma solução, favor contatar o time de suporte.
  UNKNOWN = 'UNKNOWN', // Erro inesperado, favor contatar o time de suporte.
}

export type NotificationMessage = {
  message: string
  title?: string
}

export enum SUPPLIER_TYPE {
  MIDDLEMAN = 'middleman',
  SUPPLIER = 'supplier',
  REPRESENTED_SUPPLIER = 'represented_supplier',
}

export type SuppliersTableProps = {
  options?: {
    suppliers: Producer[]
    skus: {
      skus: SKU[]
      labelValueSKUs: {
        value: string
        label: string
      }[]
    }
    categories: string[]
  }
}

export type Tag = {
  id: string
  name: string
}
