import { Badge, Box, Center, Image, Loader, Space, Stack } from '@mantine/core'
import {
  IconBoxSeam,
  IconChartLine,
  IconCubePlus,
  IconMessageCircle,
  IconPackageImport,
  IconPencil,
  IconReceipt,
  IconTractor,
} from '@tabler/icons-react'
import type { NavbarContentProps } from 'src/types'
import styles from './Navbar.module.css'
import NavbarItem from './NavbarItem'
import { useQuery } from '@tanstack/react-query'
import { countUnreadChannels } from 'src/requests/channels/countUnreadChannels'

function MessagesIcon() {
  const { data: unreadChannels, isLoading: isLoadingUnreadChannels } = useQuery({
    queryKey: ['channels', 'unread'],
    queryFn: countUnreadChannels,
    refetchInterval: import.meta.env.VITE_CHANNELS_REFETCH_INTERVAL,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  return (
    <Box pos={'relative'}>
      {(isLoadingUnreadChannels || (!isLoadingUnreadChannels && !!unreadChannels?.count)) && (
        <Badge fz={12} pos="absolute" top={0} right={-4} size="sm" circle color="#4fc877">
          <Center>{isLoadingUnreadChannels ? <Loader size={12} color="white" /> : unreadChannels?.count}</Center>
        </Badge>
      )}
      <IconMessageCircle size={26} />
    </Box>
  )
}

const navItems = [
  {
    to: '/fornecedores',
    icon: <IconTractor size={26} />,
    text: 'Fornecedores',
  },
  {
    to: '/mensagens',
    icon: <MessagesIcon />,
    text: 'Mensagens',
  },
  {
    text: 'Ofertas',
    children: [
      { to: '/ofertas/sku', text: 'Ativas', icon: <IconBoxSeam size={26} /> },
      { to: '/ofertas/criar', text: 'Criar oferta', icon: <IconCubePlus size={26} /> },
      { to: '/ofertas', text: 'Histórico', icon: <IconPackageImport size={26} /> },
    ],
  },
  {
    text: 'Pedidos',
    children: [
      { to: '/pedidos-abertos', text: 'Em aberto', icon: <IconPencil size={26} /> },
      { to: '/pedidos-enviados', text: 'Enviados', icon: <IconReceipt size={26} /> },
    ],
  },
  {
    to: '/relatorios',
    text: 'Relatórios',
    icon: <IconChartLine size={26} />,
    hidden: true,
  },
]

export type NavItemType = (typeof navItems)[number]

export default function NavbarContent(props: NavbarContentProps) {
  const { isMobile } = props

  return (
    <>
      {isMobile && <Image src="/logo.png" fit="contain" height={20} alt="logo" className={styles.logo} />}
      {isMobile && <Space h="lg" />}
      <Stack justify="space-between">
        <Stack gap="xs">
          {navItems
            .filter((item) => !item.hidden)
            .map((item, index) => (
              <NavbarItem key={index} item={item} />
            ))}
        </Stack>
      </Stack>
    </>
  )
}
