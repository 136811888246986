import { Flex, Pagination, Table } from '@mantine/core'
import { CustomTableHead } from './Head'
import { CustomTableBody } from './Body'
import { ReceivedOffer } from 'src/types'

type CustomTableProps = {
  items: ReceivedOffer[]
  totalPages: number
  activePage: number
  onChangePage: (num: number) => void
}

export function CustomTable(props: CustomTableProps) {
  return (
    <>
      <Table.ScrollContainer minWidth={300}>
        <Table withRowBorders={false} verticalSpacing="sm">
          <CustomTableHead />
          <CustomTableBody items={props.items} />
        </Table>
      </Table.ScrollContainer>
      <Flex px={25} justify="center" mt="xs">
        <Pagination total={props.totalPages} value={props.activePage} onChange={props.onChangePage} />
      </Flex>
    </>
  )
}
